<template>
    <div>
      <el-select v-model="selectedCouponType" placeholder="请选择优惠券类型">
        <el-option label="满减券" value="满减券"></el-option>
        <el-option label="打折券" value="打折券"></el-option>
      </el-select>
      <div style="display: flex;flex-direction: column;justify-content: center;" v-if="selectedCouponType === '满减券'">
        <el-input v-model="condition" placeholder="满额条件" style="margin-bottom: 10px;"></el-input>
        <el-input v-model="result" placeholder="减免金额" style="margin-bottom: 10px;"></el-input>
        <el-input v-model="num" placeholder="数量" style="margin-bottom: 10px;"></el-input>
        <el-date-picker
          v-model="startDate"
          type="date"
          placeholder="开始日期"
          style="margin-bottom: 10px;"
        ></el-date-picker>
        <el-date-picker
          v-model="endDate"
          type="date"
          placeholder="结束日期"
          style="margin-bottom: 10px;"
        ></el-date-picker>
        <el-button @click="addCouponMe" type="primary">增加满额优惠券</el-button>
        <el-button style="margin-top: 20px;" @click="publishCouponMe" type="success">发布满额优惠券</el-button>
      </div>
  
      <div style="display: flex;flex-direction: column;justify-content: center;" v-if="selectedCouponType === '打折券'">
        <el-input v-model="condition" placeholder="满额条件" style="margin-bottom: 10px;"></el-input>
        <el-input v-model="result" placeholder="折扣率" style="margin-bottom: 10px;"></el-input>
        <el-input v-model="num" placeholder="数量" style="margin-bottom: 10px;"></el-input>
        <el-date-picker
          v-model="startDate"
          type="date"
          placeholder="开始日期"
          style="margin-bottom: 10px;"
        ></el-date-picker>
        <el-date-picker
          v-model="endDate"
          type="date"
          placeholder="结束日期"
          style="margin-bottom: 10px;"
        ></el-date-picker>
        <el-button @click="addCouponDz" type="primary">增加折扣优惠券</el-button>
        <el-button style="margin-top: 20px;" @click="publishCouponDz" type="success">发布折扣优惠券</el-button>
      </div>
  
      <div v-for="(coupon, index) in coupons" :key="index" style="display: flex; align-items: center; margin-bottom: 10px;">
        <span>类型:{{ coupon.class }} 条件满<strong>{{ coupon.value.condition_value }}</strong> 立减或打折<strong>{{ coupon.value.result }}</strong> 发布数量 <strong>{{ coupon.value.num }}</strong>张，开始时间<strong>{{ formatDate(coupon.startDate) }}</strong> 至 <strong>{{ formatDate(coupon.endDate) }}</strong>结束</span>
        <el-button @click="removeCoupon(index)" type="danger">删除</el-button>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import request from "../utils/request"; // import your request method here
  
  export default {
    name: 'CouponApp',
    setup() {
      const selectedCouponType = ref('');
      const condition = ref('');
      const result = ref('');
      const num = ref('');
      const startDate = ref(null);
      const endDate = ref(null);
      const coupons = ref([]);
  
      const formatDate = (date) => {
        return new Date(date).toLocaleDateString('zh-CN');
      };
  
      const addCoupon = () => {
        if (!condition.value || !result.value || !num.value || !startDate.value || !endDate.value) {
          alert('请填写完整信息');
          return;
        }
  
        let newCoupon = {};
        if (selectedCouponType.value === '满减券') {
          newCoupon = {
            class: '满减券',
            value: {
              condition_value: condition.value,
              result: result.value,
              num: num.value,
            },
            startDate: new Date(startDate.value),
            endDate: new Date(endDate.value),
          };
        } else if (selectedCouponType.value === '打折券') {
          newCoupon = {
            class: '打折券',
            value: {
              condition_value: condition.value,
              result: result.value,
              num: num.value,
            },
            startDate: new Date(startDate.value),
            endDate: new Date(endDate.value),
          };
        }
        coupons.value.push(newCoupon);
        condition.value = '';
        result.value = '';
        num.value = '';
        startDate.value = null;
        endDate.value = null;
      };
  
      const addCouponMe = () => {
        addCoupon();
      };
  
      const addCouponDz = () => {
        addCoupon();
      };
  
      const publishCouponMe = () => {
        publishCouponDz();
      };
  
      const publishCouponDz = () => {
        console.log(coupons._rawValue);
        // Call your actual publish coupon method here
        request({
          method: "POST",
          url: "/admin/addcouponadmin",
          data:{
            coupons:coupons._rawValue
          }
        }).then((res)=>{
            if(res.data.status==200){
                alert("发布优惠券成功")
            }else{
                alert("发布优惠券失败请联系技术人员")
            }
        }).catch((res)=>{
            alert("发布优惠券失败请联系技术人员")
        })
      };
  
      const removeCoupon = (index) => {
        coupons.value.splice(index, 1);
      };
  
      return {
        selectedCouponType,
        condition,
        result,
        num,
        startDate,
        endDate,
        coupons,
        addCouponMe,
        addCouponDz,
        publishCouponMe,
        publishCouponDz,
        removeCoupon,
        formatDate
      };
    },
  };
  </script>
  