<template>
    <div id="post" style="min-width: 0; || width: 0">
      <el-header  style="padding-left:0;display: flex;flex-direction: row;justify-content: space-between;">
              <div style="width:500px;font-size: 25px;">
                  退款中订单
              </div>
              <div style="width: 450px;display: flex;flex-direction: row;justify-content: space-between;">
                 
              </div>
      </el-header>
      <el-table
      :data="goods"
      style="width: 100%;text-align: center;"
      :default-sort = "{prop: 'date', order: 'descending'}"
      height="710"
      @selection-change="handleSelectionChange">
      <el-table-column prop="refundstyle" label="退货物流" width="100" align="center"></el-table-column>
      <el-table-column prop="refundcode" label="物流编号" width="150" align="center"></el-table-column>
      <el-table-column prop="title" label="商品标题" width="150" align="center"></el-table-column>
      <el-table-column prop="num" label="下单数量" width="100" align="center"></el-table-column>
      <el-table-column prop="ordertuple" label="下单属性" width="150" align="center"></el-table-column>
      <el-table-column prop="name" label="下单姓名" width="150" align="center"></el-table-column>
      <el-table-column prop="address" label="下单地址" width="150" align="center"></el-table-column>
      <el-table-column prop="phone" label="手机号" width="150" align="center"></el-table-column>
      <el-table-column prop="price" label="实付价格" width="100" align="center"></el-table-column>
      <el-table-column prop="ordercode" label="订单编号" width="100" align="center"></el-table-column>
      <el-table-column sortable label="图片" width="260" align="center">
        <template #default="{ row }">
          <img :src="`https://sjgj.xpwangluo.cn/static/${row.image}`" alt="Image" height="110" width="110" @click="showImg(`https://sjgj.xpwangluo.cn/static/${row.image}`)">
        </template>
      </el-table-column>
      <el-table-column sortable fixed="right" label="操作" width="250"  align="center">
        <template #default="{$index, row}">
          <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;padding: 14px;">
              <div>
                <el-button
                  @click.prevent="openChangeWindow($index, row)"
                  type="primary"
                  size="large">
                  物流轨迹
                </el-button>
              </div>
              <div>
                <el-button
                  @click.prevent="exitedGoods($index, row)"
                  type="primary"
                  size="large">
                  可以退货
                </el-button>
              </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <el-dialog v-model="dialogVisibleOut" width="650px">
      <img w-full :src="dialogImageUrlOut" width="100%" alt="预览" />
    </el-dialog>
    <el-dialog title="发货信息" v-model="logisticDialogVisible" width="50%">
        <div v-for="(item, index) in logisticInfo" :key="index">
            <p style="padding-top: 10px;">{{ item.AcceptTime }} - {{ item.AcceptStation }}</p>
        </div>
    </el-dialog>
  </template>
  
  <script>
  import {Plus, CirclePlus, Download, Delete} from "@element-plus/icons-vue"
  import request from "../utils/request"
  export default {
    name: "myPost",
    components:{
      Plus,
      CirclePlus,
      Download,
      Delete
    },
    data() {
      return {
        goods:[],
        dialogImageUrl: '',
        dialogVisible: false,
        disabled: false,
        dialogImageUrlOut:'',
        dialogVisibleOut:false,
        logisticInfo: [], // 存储物流信息
        logisticDialogVisible: false, // 控制物流信息弹窗的显示
      };
    },
    methods: {

      handleSelectionChange(selection) {
        this.multipleSelection = selection;
      },

      openChangeWindow(index,row) {
        console.log(index,row);
        // 在这里可以做一些处理，例如打开编辑弹窗等
        console.log(index,row);
        if(row.refundcode==null || row.refundcode.length==0){
            this.$notify({
              title: '暂无物流',
              message: "请联系对方尽快发货",
              position: 'bottom-right'
            });
            return
        }
        console.log("快递方式",row.refundstyle,"快递单号",row.refundcode);
        request({
            method:"POST",
            url:"/logtraject",
            data:{
                kuaidistyle:row.refundstyle,
                kuaidicode:row.refundcode
            }
            }).then((res)=>{
                console.log("物流信息",res);
                this.logisticInfo=res.data.msg
                this.logisticDialogVisible=true
            })  
      },
      getSvip(){
        request({
            method:"GET",
            url:"/admin/passingrefund",
        }).then((res)=>{
            console.log(res);
            if(res.data.status=="200"){
                this.goods=res.data.msg
            }else{
                alert("服务器错误")
            }
        })   
      },
      exitedGoods(index,row){
         /* /admin/couldtorefund */
         request({
            url:"/admin/couldtorefund",
            method:"POST",
            data:{
                id:row.id
            }
        }).then((res)=>{
            console.log("返回信息",res);
            if(res.data.status=="200"){
                alert("已加入退款列表")
            }
        }).catch((res)=>{
            alert("失败，请联系技术工作人员")
        })
      }
    },
    mounted:function(){
          this.getSvip()
    },
  };
  </script>
  
  <style scoped>
   .upload{
      width: 800px;
    }
  
    .demo-tabs > .el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
  }
  
    .el-tabs--right .el-tabs__content,
    .el-tabs--left .el-tabs__content {
      height: 100%;
  }
  
  .money {
    font-size: 20px;
    color: #fb1515;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .button {
    padding: 0;
    min-height: auto;
  }
  
  .image {
    width: 100%;
    display: block;
  }
  </style>
  