<template>
    <div id="post" style="min-width: 0; || width: 0">
      <el-header  style="padding-left:0;display: flex;flex-direction: row;justify-content: space-between;">
              <div style="width:500px;font-size: 25px;">
                 基地会员订单退款待处理
              </div>
              <div style="width: 450px;display: flex;flex-direction: row;justify-content: space-between;">
                 
              </div>
      </el-header>
      <el-table
      :data="goods"
      style="width: 100%;text-align: center;"
      :default-sort = "{prop: 'date', order: 'descending'}"
      height="710"
      @selection-change="handleSelectionChange">
      <el-table-column  prop="refundstyle" label="退货物流" width="150" align="center"></el-table-column>
      <el-table-column  prop="refundcode" label="退货单号" width="150" align="center"></el-table-column>
      <el-table-column  prop="title" label="商品标题" width="150" align="center"></el-table-column>
      <el-table-column  prop="num" label="下单数量" width="100" align="center"></el-table-column>
      <el-table-column  prop="ordertuple" label="下单属性" width="150" align="center"></el-table-column>
      <el-table-column  prop="name" label="下单姓名" width="150" align="center"></el-table-column>
      <el-table-column  prop="address" label="下单地址" width="150" align="center"></el-table-column>
      <el-table-column  prop="phone" label="手机号" width="150" align="center"></el-table-column>
      <el-table-column  prop="price" label="实付价格" width="100" align="center"></el-table-column>
      <el-table-column sortable label="图片" width="260" align="center">
        <template #default="{ row }">
          <img :src="`https://sjgj.xpwangluo.cn/static/${row.image}`" alt="Image" height="110" width="110" @click="showImg(`https://sjgj.xpwangluo.cn/static/${row.image}`)">
        </template>
      </el-table-column>
      <el-table-column sortable fixed="right" label="操作" width="100"  align="center">
        <template #default="{$index, row}">
          <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;padding: 14px;">
              <div>
                <el-button
                  @click.prevent="openChangeWindow($index, row)"
                  type="primary"
                  size="large">
                  退款
                </el-button>
              </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    </div>
    <el-dialog v-model="dialogVisibleOut" width="650px">
      <img w-full :src="dialogImageUrlOut" width="100%" alt="预览" />
    </el-dialog>
  </template>
  
  <script>
  import {Plus, CirclePlus, Download, Delete} from "@element-plus/icons-vue"
  import request from "../utils/request"
  export default {
    name: "myPost",
    components:{
      Plus,
      CirclePlus,
      Download,
      Delete
    },
    data() {
      return {
        goods:[],
        dialogImageUrl: '',
        dialogVisible: false,
        disabled: false,
        dialogImageUrlOut:'',
        dialogVisibleOut:false,
      };
    },
    methods: {

      handleSelectionChange(selection) {
        this.multipleSelection = selection;
      },

      openChangeWindow(index,row) {
        console.log(index,row);
        var price = parseFloat(row.price) * 100
        var code = row.ordercode
         // 获取当前时间戳
        const timestamp = Date.now();
        
        // 生成一个6位随机数
        const randomNum = Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
        
        // 拼接字符串
        var orderNumber = `TK${timestamp}${randomNum}`;
        // 在这里可以做一些处理，例如打开编辑弹窗等
        price = Math.round(price);
        request({
            url:"/passrefundone",
            method:"POST",
            data:{
              out_trade_no: code,
              amount: {
                refund: price,  
                total: price, 
                currency: "CNY" 
              },
              out_refund_no: orderNumber
            }
        }).then((res)=>{
            console.log("返回信息",res);
            this.comebackorder(row.id)
        }).catch((res)=>{
            alert("失败，请联系技术工作人员")
        })
      },
      comebackorder(id){
        request({
            url:"/admin/finishrefundvip",
            method:"POST",
            data:{
              id:id
            }
        }).then((res)=>{
            console.log("返回信息",res);
            if(res.data.status=="200"){
                alert("退款完成")
                this.getSvip()
            }
        }).catch((res)=>{
            alert("失败，请联系技术工作人员")
        })
      },
      getSvip(){
        request({
            method:"GET",
            url:"/admin/collnoredundvip",
        }).then((res)=>{
            console.log(res);
            if(res.data.status=="200"){
                this.goods=res.data.msg
            }else{
                alert("服务器错误")
            }
        })   
      },
    },
    mounted:function(){
          this.getSvip()
    },
  };
  </script>
  
  <style scoped>
   .upload{
      width: 800px;
    }
  
    .demo-tabs > .el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
  }
  
    .el-tabs--right .el-tabs__content,
    .el-tabs--left .el-tabs__content {
      height: 100%;
  }
  
  .money {
    font-size: 20px;
    color: #fb1515;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .button {
    padding: 0;
    min-height: auto;
  }
  
  .image {
    width: 100%;
    display: block;
  }
  </style>
  