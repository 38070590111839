<!--
 * @Author: xpaibiancheng 486957763@qq.com
 * @Date: 2024-02-23 01:00:15
 * @LastEditors: xpaibiancheng 486957763@qq.com
 * @LastEditTime: 2024-02-23 02:59:26
 * @FilePath: \sjgj_Vue\src\pages\updatemguser.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <el-form @submit.prevent="changePassword">
      <el-form-item label="新密码">
        <el-input v-model="newPassword" type="password" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="changePassword">确定</el-button>
      </el-form-item>
    </el-form>
  </template>
  
  <script>
  import { ref } from 'vue';
  import { ElForm, ElFormItem, ElInput, ElButton } from 'element-plus';
  import request from "../utils/request"
  
  export default {
    components: {
      ElForm,
      ElFormItem,
      ElInput,
      ElButton
    },
    setup() {
      const newPassword = ref('');
  
      const changePassword = () => {
        const user = localStorage.getItem('user');
        if (!user) {
          alert('未检测到用户信息，请重新登录');
          return;
        }
        
        request.post('/admin/changepassword', { user, newPassword: newPassword.value })
          .then(response => {
            alert(response.data.message); // 假设后端返回的有一个message字段
            this.$router.push({ name: 'login' });
          })
          .catch(error => {
            console.error(error);
            alert('更改失败，请联系管理员');
          });
      };
  
      return {
        newPassword,
        changePassword
      };
    }
  };
  </script>
  