<template>
    <div id="user">
      <el-header  style="padding-left:0;padding-right: 0;display: flex;flex-direction: row;justify-content: space-between;">
              <div>
                  <el-input v-model="search" size="millde" placeholder="搜索订单" style="width: 300px;margin-right: 10px;"/>
                  <el-button type="primary">搜索</el-button>
              </div>
              <!-- <div>
                  <el-input type="number" v-model="search2" size="millde" placeholder="设定会员价格" style="width: 200px;margin-right: 10px;"/>
                  <el-button text plain @click="open" type="success">设定</el-button>
              </div> -->
             
      </el-header>
      <el-table :data="user" style="width: 100%" class="yuan">
        <el-table-column align="center" width="240" sortable label="id" prop="id" />
        <el-table-column align="center" sortable width="300" label="订单号" prop="code" />
        <el-table-column sortable label="手机号" prop="phone" />
        <el-table-column sortable label="价格" prop="price" />
        <el-table-column sortable label="订单类型" prop="order" />
        <el-table-column sortable label="支付时间" prop="time" />
        <el-table-column sortable label="订单状态" prop="status">
       
      </el-table-column>
        <!-- <el-table-column align="right" label="操作">
          <template #default="{ $index, row }">
   
            <el-popconfirm title="禁用此用户？"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="blackIt($index, row)"
            >
              <template #reference>
                <el-button size="small" type="primary" @click="openDrawer(row)">禁用</el-button>
              </template>
            </el-popconfirm>
            <el-popconfirm 
            title="确认删除吗？（删除后不可恢复）"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="handleDelete($index, row)"
            >
              <template #reference>
                <el-button size="small" type="danger">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- <el-drawer v-model="drawer" title="编辑用户" :with-header="false">
        <h1 style="font-size: 30px;"></h1>
      </el-drawer> -->
    </div>
  </template>
  
  <script >
  // import { h,} from 'vue'
  // import { ElMessageBox } from 'element-plus'
  // import axios from  'axios'
  // import { ElNotification } from 'element-plus'
  import request from "../utils/request"
  export default {
    
    name: 'myUser',
    
    data() {
  
  
      return {
        
        user: [],
        drawer: false,
        editingUser: null,
        search:'',
        search2:'',
     
      };
      
    },
    methods: {
      openDrawer(row) {
        this.editingUser = row;
        // this.drawer = true;
        console.log(this.drawer);
      },
      blackIt(index, row){
        row.alive = 0
      },
      aliveIt(index, row){
        row.alive = 1
      },
      handleDelete(index, row) {
        this.user = this.user.filter((item) => item.id !== row.id);
      },
      getSvip(){

request({
  method:"GET",
  url:"/admin/paysvip",
}).then((res)=>{
  console.log(res);
  if(res.data.status==200){
    var responseData=res.data.msg
    this.user=responseData
    
   
  }
})          
} 
      
    },
    mounted:function(){
    
    this.getSvip()
},
  };
  
  </script>
  
  <style scoped>
  
  </style>
  