<template>
    <div id="user">
      <el-header  style="padding-left:0;padding-right: 0;display: flex;flex-direction: row;justify-content: space-between;">
              <div>
                  <el-input v-model="search2" size="millde" placeholder="新增分类名称" style="width: 200px;margin-right: 10px;"/>
                  <el-button text plain @click="open" type="success">新增分类</el-button>
              </div>
             
      </el-header>
      <el-table :data="user" style="width: 100%" class="yuan">
        <el-table-column align="center" width="100" sortable label="id" prop="id" />
        <el-table-column sortable prop="time" label="增加分类时间" width="300" align="center">
          <template #default="{ row }">
            {{ formatDate(row.time) }}
          </template>
        </el-table-column>
        <el-table-column sortable label="分类" prop="name">
        </el-table-column>
        <el-table-column align="right" label="操作">
          <template #default="{ $index, row }">
            <el-popconfirm 
            title="确认删除吗？（删除后不可恢复）"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="handleDelete($index, row)"
            >
              <template #reference>
                <el-button size="small" type="danger">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- <el-drawer v-model="drawer" title="编辑用户" :with-header="false">
        <h1 style="font-size: 30px;"></h1>
      </el-drawer> -->
    </div>
  </template>
  
  <script >
  import { h,} from 'vue'
  import { ElMessageBox } from 'element-plus'
  import axios from  'axios'
  import { ElNotification } from 'element-plus'
  import request from "../utils/request"
  export default {
    
    name: 'myUser',
    
    mounted() { 
    console.log("name");
    axios.get('http://127.0.0.1:5000/admin/svip').then(res=>{
      
      console.log(res);
    })
    .catch(err=>{
      console.log(err);
    })
  },


    
    data() {
  
  
      return {
        
        user: [],
        drawer: false,
        editingUser: null,
        search:'',
        search2:'',
     
      };
      
    },
    methods: {
      formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1; // getUTCMonth() 返回的月份是从 0 开始的
        const day = date.getUTCDate();
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      },
      openDrawer(row) {
        this.editingUser = row;
        // this.drawer = true;
        console.log(this.drawer);
      },
      blackIt(index, row){
        row.alive = 0
      },
      aliveIt(index, row){
        row.alive = 1
      },
      handleDelete(index, row) {
        var shop_id = this.user[index].id
        console.log(shop_id);
        this.user = this.user.filter((item) => item.id !== row.id);
        request({
                method:"POST",
                url:"/admin/delshopclass",
                data:{
                    id:shop_id
                }
            }).then((res)=>{
                    
            })
      },
      getSvip(){
            request({
                method:"GET",
                url:"/admin/get_shopClass",
            }).then((res)=>{
                console.log(res);
            if(res.data.status==200){
                    var responseData=res.data.msg
                    console.log(responseData);
                    this.user=responseData
                }
            })          
      },
      open(){
        ElMessageBox({
      
      message: h('p', null, [
        h('span', null, '确定新增分类: '),
        h('i', { style: 'color: teal'  }, this.search2),
        
      ]),
      
    }).then((res)=>{
      console.log(res);
      // 使用axios实例发送POST请求
      axios.post('https://sjgj.xpwangluo.cn/admin/addclassshop', `name=${this.search2}`)
            .then(response => {
              console.log(response)
              if(response.data.status=='200'){
                ElNotification({
                    title: '提示',
                    message: '增加分类成功',
                    type: 'success',
                  })
                  this.getSvip()
                      
              }
            })
            .catch(error => {
              // 登录失败，提示错误信息
              console.log(error)
              
            })
    })
  
    }
      
      
      
    },
    mounted:function(){
        this.getSvip()
    },
  };
  
  </script>
  
  <style scoped>
  
  </style>
  