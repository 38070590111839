<template>
  <div id="banner">
    <el-container class="addNotice">
      <el-main>
        <el-header style="padding:0;"><h1 class="title">公告发布</h1></el-header>
        <el-main>
          <el-form>
              <el-form-item>
                <el-input v-model="cont" placeholder="输入公告内容："/>
              </el-form-item>
              <!-- 新增选择方式下拉框 -->
              <el-form-item>
                <el-select v-model="selectType" placeholder="请选择发布方式">
                  <el-option label="全局" value="global"></el-option>
                  <el-option label="普通会员" value="normal"></el-option>
                  <el-option label="平台会员" value="platform"></el-option>
                </el-select>
              </el-form-item>
              <!-- 新增是否开启超链接单选按钮 -->
              <el-form-item>
                <el-radio-group v-model="isHyperlink">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <!-- 新增链接地址输入框 -->
              <el-form-item v-if="isHyperlink">
                <el-input v-model="hyperlink" placeholder="请输入链接地址"/>
              </el-form-item>
              <el-form-item>
                <el-button style="float: right;" type="primary" @click="onSubmit">发布</el-button>
              </el-form-item>
          </el-form>
        </el-main>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {
   Plus,
   CirclePlus,
   Download,
   Delete
} from '@element-plus/icons-vue'
import request from "../utils/request"
export default {
    name: "myBanner",
    components: {
      Plus,
      CirclePlus,
      Download,
      Delete
    },
    data() {
        return {
          cont: "",
          selectType: "", // 发布方式
          isHyperlink: true, // 是否开启超链接，默认为是
          hyperlink: "", // 超链接地址
          file: [],
          dialogImageUrl: '',
          dialogVisible: false,
          disabled: false
        };
    },
    methods: {
      onSubmit() {
        console.log('发布成功');
        console.log(`发布内容:${this.cont},发布方式: ${this.selectType}, 是否开启超链接: ${this.isHyperlink ? '是' : '否'}, 链接地址: ${this.hyperlink}`);
        request({
          method:"POST",
          url:"/admin/publish",
          data:{
            text:this.cont,
            style:this.selectType,
            judgeurl:this.isHyperlink,
            url:this.hyperlink
          }
        }).then((res)=>{
          if(res.data.status==200){
            this.$notify({
              title: '发布成功',
              message: this.cont,
              position: 'bottom-right'
            });
          }else{
            alert("发布失败")
          }
        })
      },
    }
}
</script>

<style scoped>
  .small{
    padding: 10px;
  }
  .show{
    height: 450px;
    padding: 0;
    border-radius: 10px;
    overflow: hidden;
  }
  .title{
    color: rgb(127, 101, 255);
    font-size: 30px;
    border-left: 10px solid rgb(64, 211, 118);
    padding-left: 4px;
  }
  .add{
    margin-top: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    overflow: hidden;
    margin-left: 60px;
    margin-right: 60px;
    box-shadow: 0 2px 8px rgba(128, 128, 128, 0.545);
  }
  .addNotice{
    margin-top: 20px;
    margin-left: 60px;
    margin-right: 60px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(128, 128, 128, 0.545);
  }
</style>
