<template>
  <div>
    <el-table :data="users" style="width: 100%">
      <el-table-column prop="id" label="ID" width="150"></el-table-column>
      <el-table-column prop="name" label="姓名" width="350"></el-table-column>
      <el-table-column prop="username" label="用户名" width="350"></el-table-column>
      <el-table-column label="操作" width="350">
        <template #default="{ row }">
          <el-button size="mini" @click="deleteUser(row.id)">删除</el-button>
          <el-button size="mini" @click="editPermissions(row)">权限管理</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="permissionsDialogVisible" title="权限管理">
      <el-form :model="editingUser">
        <el-form-item label="内容管理">
          <el-select v-model="editingUser.content_management" multiple placeholder="请选择">
            <el-option label="内容管理" value="contentManagement"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户管理">
          <el-select v-model="editingUser.user_management" multiple placeholder="请选择">
            <el-option label="普通会员管理" value="normalMemberManagement"></el-option>
            <el-option label="平台会员管理" value="platformMemberManagement"></el-option>
            <el-option label="支付订单管理" value="paymentOrderManagement"></el-option>
            <el-option label="基地会员费用" value="baseMemberFee"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品管理">
          <el-select v-model="editingUser.product_management" multiple placeholder="请选择">
            <el-option label="普通商品" value="normalProduct"></el-option>
            <el-option label="添加管理" value="addManagement"></el-option>
            <el-option label="添加分类" value="addClassification"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="普通会员订单管理">
          <el-select v-model="editingUser.normal_order_management" multiple placeholder="请选择">
            <el-option label="待发货订单" value="pendingShipmentOrder"></el-option>
            <el-option label="已发货订单" value="shippedOrder"></el-option>
            <el-option label="已签收订单" value="receivedOrder"></el-option>
            <el-option label="申请退款订单" value="refundApplicationOrder"></el-option>
            <el-option label="退款通过订单" value="refundApprovedOrder"></el-option>
            <el-option label="退款中订单" value="refundingOrder"></el-option>
            <el-option label="退款待处理订单" value="refundPendingOrder"></el-option>
            <el-option label="退款已完成订单" value="refundCompletedOrder"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="基地会员订单管理">
          <el-select v-model="editingUser.base_order_management" multiple placeholder="请选择">
            <el-option label="待处理新订单" value="pendingNewOrder"></el-option>
            <el-option label="已处理订单" value="processedOrder"></el-option>
            <el-option label="退款订单物流" value="refundOrderLogistics"></el-option>
            <el-option label="待处理退款" value="refundPending"></el-option>
            <el-option label="退款已处理" value="refundProcessed"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="优惠券管理">
          <el-select v-model="editingUser.coupon_management" multiple placeholder="请选择">
            <el-option label="上新优惠券" value="newCoupon"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="permissionsDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="updatePermissions">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ref } from 'vue';
import { ElTable, ElTableColumn, ElButton, ElDialog, ElForm, ElFormItem, ElSelect, ElOption } from 'element-plus';
import request from '../utils/request';

export default {
  components: {
    ElTable,
    ElTableColumn,
    ElButton,
    ElDialog,
    ElForm,
    ElFormItem,
    ElSelect,
    ElOption,
  },
  setup() {
    const users = ref([]);
    const permissionsDialogVisible = ref(false);
    const editingUser = ref({});

    const loadUsers = async () => {
      const response = await request.get('/admin/users');
      users.value = response.data;
    };

    const deleteUser = async (id) => {
      await request.delete(`/admin/user/${id}`);
      loadUsers();
    };

    const editPermissions = (user) => {
      editingUser.value = { ...user };
      permissionsDialogVisible.value = true;
    };

    const updatePermissions = async () => {
      await request.patch(`/admin/user/${editingUser.value.id}`, {
        content_management: editingUser.value.content_management,
        user_management: editingUser.value.user_management,
        product_management: editingUser.value.product_management,
        normal_order_management: editingUser.value.normal_order_management,
        base_order_management: editingUser.value.base_order_management,
        coupon_management: editingUser.value.coupon_management,
      });
      permissionsDialogVisible.value = false;
      loadUsers();
    };

    loadUsers();

    return {
      users,
      permissionsDialogVisible,
      editingUser,
      deleteUser,
      editPermissions,
      updatePermissions,
    };
  },
};
</script>
