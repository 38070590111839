<template>
  <div class="frame">
    <router-view></router-view>
  </div>
</template>
<script>
export default{

  methods:{
    tologin(){
      this.$router.push({ name: 'login' });
    }
  },
  created(){
      //自动加载indexs方法
      this.tologin();
  }
}
</script>