<template>
  <div id="detail">
    <el-header  style="padding-left:0;display: flex;flex-direction: row;justify-content: space-between;">
            <div style="width:500px">
                <el-input v-model="search" size="millde" placeholder="搜索" style="width: 300px;margin-right: 10px;"/>
                <el-button type="primary">搜索</el-button>
            </div>
            <div style="display: flex;flex-direction: row;justify-content: space-between;">
                <div style="margin-right: 20px;"><el-button type="success" @click="handleBatchDelivery">批量发货</el-button></div>
                <div>
                  <el-button type="danger"  @click="open">批量取消</el-button>
                </div>
            </div>
    </el-header>
    <el-table :data="shops" style="width: 100%" class="yuan" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="80" align="center" ref="multipleTable"></el-table-column>
        <el-table-column sortable label="订单号" width="200" prop="id" align="center"/>
        <el-table-column sortable label="商品名" prop="name" width="130" align="center"/>
        <el-table-column sortable label="收货信息" prop="phone" width="120" align="center"/>
        <el-table-column sortable label="收货地址" prop="position" width="300" align="center"/>
        <el-table-column sortable label="用户类型" prop="type" width="200" align="center"/>
        <el-table-column sortable label="下单日期" prop="date" width="200" align="center"/>
      <el-table-column sortable label="订单状态" align="center">
        <template #default="{ row }">
          <div style="position: relative;">
            <div v-if="row.styleId==1" style="text-align: center;background-color: #00ff2a;position: relative;color: white;">已发货<span style="white-space: pre;">&nbsp;&nbsp;&nbsp;</span></div>
            <div v-if="row.styleId==0" style="text-align: center;background-color: #fff;position: relative">未发货<span style="white-space: pre;">&nbsp;&nbsp;&nbsp;</span></div>
            <div v-if="row.styleId==-1" style="text-align: center;background-color: #ff0000;color: white;position: relative">已取消<span style="white-space: pre;">&nbsp;&nbsp;&nbsp;</span></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="{ $index, row }">
          <el-button size="small" type="success" @click="handleEdit($index, row)">确认发货</el-button>
          <el-button size="small" type="danger" @click="handleDelete($index, row)">取消订单</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>



<script>
export default {
  name: 'myDetail',
  props: {
    tableData: Array,
  },
  data(){
    return{
      shops:[
        {id:100,name:"张牧之",phone:133990,date:"2020年2月6日",type:"学员",position:"山东省临沂市",styleId:0},
        {id:101,name:"王牧之",phone:133990,date:"2020年2月7日",type:"普通客户",position:"山东省临沂市",styleId:1},
        {id:102,name:"李牧之",phone:133990,date:"2020年2月8日",type:"普通客户",position:"山东省临沂市",styleId:0},
        {id:103,name:"刘牧之",phone:133990,date:"2020年2月9日",type:"普通客户",position:"山东省临沂市",styleId:0},
        {id:104,name:"宋牧之",phone:133990,date:"2020年2月10日",type:"普通客户",position:"山东省临沂市",styleId:0},
        {id:105,name:"秦牧之",phone:133990,date:"2020年2月11日",type:"普通客户",position:"山东省临沂市",styleId:0},
      ],
      multipleSelection: []
    }
  },
  methods:{
    handleSelectionChange(selection) {
      this.multipleSelection = selection;
    },
    handleBatchDelivery() {
      this.multipleSelection.forEach((row) => {
        if(row.styleId==0){
          this.$notify({
            title: '发货成功',
            message: '恭喜！已成功发货！',
            type: 'success'
          });
          row.styleId = 1; // 将选中的订单状态设为已发货
        }else if(row.styleId==-1){
          this.$notify({
            title: '操作失败',
            message: '订单已被取消',
            type: 'warning'
          });
        }
      });
    },
    handleBatchCancellation() {
      // 处理批量取消订单的逻辑
      this.multipleSelection.forEach((row) => {
        if(row.styleId==0){
          this.$notify({
            title: '已取消❗',
            message: '订单已被取消❗',
            type: 'warning'
          });
          row.styleId = -1; // 将选中的订单状态设为已取消
        }else if(row.styleId==1){
          this.$notify({
            title: '操作失败',
            message: '订单已发货',
            type: 'warning'
          });
        }
      });
    },
    handleEdit(index, row) {
      if(row.styleId==0){
        // 处理发货的逻辑
        this.$notify({
          title: '发货成功',
          message: '订单已成功发货',
          type: 'success'
        });
        row.styleId = 1;
      }else if(row.styleId==-1){
        this.$notify({
          title: '发货失败',
          message: '订单已被取消',
          type: 'warning'
        });
      }
    },
    handleDelete(index, row) {
      this.$confirm('确认取消吗？取消后订单将不可用',"取消订单",{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        if(row.styleId==0){
        // 处理取消订单的逻辑
        row.styleId = -1;
        }else if(row.styleId==1){
          this.$notify({
            title: '操作失败',
            message: '订单已发货',
            type: 'warning'
          });
        }
      })
    },
    open(){
      this.$confirm('此操作将会取消所有订单!!!, 是否继续?', '警告⚠⚠⚠⚠⚠', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        this.handleBatchCancellation()
      }).catch(() => {
                
      });
    }
  }
};
</script>

<style scoped>
</style>
