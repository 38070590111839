<template>
    <div class="index">
      <el-container class="frame-container">
            <el-header class="header">
              <a class="brand">秒小点后台管理系统</a>
              <div class="header-content">
                <div class="greet">
                  欢迎~{{ name }},您辛苦了~
                </div>
                <div>
                <el-icon v-if="adminLevel==999" size="30px" color="gold">
                  <Avatar/>
                </el-icon>
                <el-icon v-if="adminLevel!=999" size="20px" color="#41ae3c" style="margin-top: 20px;">
                  <Avatar/>
                </el-icon>
                </div>
                <div>
                  <el-popconfirm title="确认退出吗？" @confirm="exit">
                    <template #reference>
                      <el-button type="danger">退出登录</el-button>
                    </template>
                  </el-popconfirm>
                  <!-- <el-button type="primary" plain @click="backIndex">回到首页</el-button> -->
                </div>
              </div>
            </el-header>
      <el-container>
          <el-aside width="200px" class="aside">
            <el-row class="menu-row">
              <el-col :span="24">
                <el-menu
                  default-active=1
                  background-color="#545c64"
                  active-text-color="#fff"
                  text-color="#ddd"
                  router
                >
                    <el-menu-item index="2" :route="{name:'banner'}" v-if="identity==0 || permissions.contentManagement.length!=0">
                        <template #title>
                          <el-icon><Odometer /></el-icon>
                          <span>内容管理</span>
                        </template>
                    </el-menu-item>
  
                    <el-sub-menu index="3" v-if="identity==0 || permissions.userManagement.length!=0">
                        <template #title>
                          <el-icon><User /></el-icon>
                          <span>用户管理</span>
                        </template>
                        <el-menu-item index="3-1"  v-if="identity==0 || permissions.userManagement.includes('normalMemberManagement')" :route="{name:'user'}"><el-icon><Avatar /></el-icon>普通会员管理</el-menu-item>
                        <el-menu-item index="3-2"  v-if="identity==0 || permissions.userManagement.includes('platformMemberManagement')" :route="{name:'platform'}"><el-icon><Goods/></el-icon>平台会员管理</el-menu-item>
                        <el-menu-item index="3-3"  v-if="identity==0 || permissions.userManagement.includes('paymentOrderManagement')" :route="{name:'payment'}"><el-icon><Goods/></el-icon>支付订单管理</el-menu-item>
                        <el-menu-item index="3-4"  v-if="identity==0 || permissions.userManagement.includes('baseMemberFee')" :route="{name:'register'}"><el-icon><Goods/></el-icon>基地会员费用</el-menu-item>
                      </el-sub-menu>
  
                    <el-sub-menu index="4" v-if="identity==0 || permissions.productManagement.length!=0">
                        <template #title>
                            <el-icon><Box /></el-icon>
                            <span>商品管理</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item index="4-1" v-if="identity==0 || permissions.productManagement.includes('normalProduct') " :route="{name:'post'}"><el-icon><Goods/></el-icon>普通商品</el-menu-item>
                            <el-menu-item index="4-2" v-if="identity==0 || permissions.productManagement.includes('addManagement') " :route="{name:'AddShop'}"><span style="font-size: 20px;white-space: pre;">🔥 </span>添加管理</el-menu-item>
                            <el-menu-item index="4-3" v-if="identity==0 || permissions.productManagement.includes('addClassification') " :route="{name:'AddShopClass'}"><span style="font-size: 20px;white-space: pre;">🔥 </span>添加分类</el-menu-item>
                        </el-menu-item-group>
                    </el-sub-menu>
  
                    <el-sub-menu index="5" v-if="identity==0 || permissions.normalOrderManagement.length!=0">
                        <template #title>
                            <el-icon><Box /></el-icon>
                            <span>普通会员订单管理</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item v-if="identity==0 || permissions.normalOrderManagement.includes('pendingShipmentOrder') " index="5-1" :route="{name:'unsend'}">待发货订单</el-menu-item>
                            <el-menu-item v-if="identity==0 || permissions.normalOrderManagement.includes('shippedOrder') " index="5-2" :route="{name:'sending'}">已发货订单</el-menu-item>
                            <el-menu-item v-if="identity==0 || permissions.normalOrderManagement.includes('receivedOrder') " index="5-3" :route="{name:'sent'}">已签收订单</el-menu-item>
                            <el-menu-item v-if="identity==0 || permissions.normalOrderManagement.includes('refundApplicationOrder') "  index="5-4" :route="{name:'reFunding'}">申请退款订单</el-menu-item>
                            <el-menu-item v-if="identity==0 || permissions.normalOrderManagement.includes('refundApprovedOrder') " index="5-5" :route="{name:'reFundPass'}">退款通过订单</el-menu-item>
                            <el-menu-item v-if="identity==0 || permissions.normalOrderManagement.includes('refundingOrder') " index="5-6" :route="{name:'reFundingPass'}">退款中订单</el-menu-item>
                            <el-menu-item v-if="identity==0 || permissions.normalOrderManagement.includes('refundPendingOrder') " index="5-7" :route="{name:'reFundedPass'}">退款待处理订单</el-menu-item>
                            <el-menu-item v-if="identity==0 || permissions.normalOrderManagement.includes('refundCompletedOrder') " index="5-8" :route="{name:'reFunded'}">退款已完成订单</el-menu-item>
                          </el-menu-item-group>
                    </el-sub-menu>
                    
                    <el-sub-menu index="6" v-if="identity==0 || permissions.baseOrderManagement.length!=0">
                        <template #title>
                            <el-icon><Box /></el-icon>
                            <span>基地会员订单管理</span>
                        </template>
                        <el-menu-item-group>
                          <el-menu-item v-if="identity==0 || permissions.baseOrderManagement.includes('pendingNewOrder') " index="6-1" :route="{name:'vipNewOrder'}">待处理新订单</el-menu-item>
                          <el-menu-item v-if="identity==0 || permissions.baseOrderManagement.includes('processedOrder') " index="6-2" :route="{name:'vipOldOrder'}">已处理订单</el-menu-item>
                          <el-menu-item v-if="identity==0 || permissions.baseOrderManagement.includes('refundOrderLogistics') " index="6-3" :route="{name:'reFundingVip'}">退款订单物流</el-menu-item>
                          <el-menu-item v-if="identity==0 || permissions.baseOrderManagement.includes('refundPending') " index="6-4" :route="{name:'reFundedVip'}">待处理退款</el-menu-item>
                          <el-menu-item v-if="identity==0 || permissions.baseOrderManagement.includes('refundProcessed') " index="6-5" :route="{name:'reFundvipcomp'}">退款已处理</el-menu-item>
                        </el-menu-item-group>
                    </el-sub-menu>
                    <el-sub-menu index="7" v-if="identity==0">
                        <template #title>
                          <el-icon><Avatar /></el-icon>
                          <span>后台管理员管理</span>
                        </template>
                        <el-menu-item-group>
                          <el-menu-item index="7-1"  :route="{name:'addAdmin'}">创建成员角色</el-menu-item>
                          <el-menu-item index="7-2"  :route="{name:'mgAdmin'}">管理成员角色</el-menu-item>
                          <el-menu-item index="7-3"  :route="{name:'updateMgUser'}">超级管理密码更改</el-menu-item>
                          <el-menu-item index="7-4"  :route="{name:'addShopMg'}">店铺上新</el-menu-item>
                        </el-menu-item-group>
                    </el-sub-menu>
                    <el-sub-menu index="8" v-if="identity==0 || permissions.couponManagement.length!=0">
                        <template #title>
                          <el-icon><Avatar /></el-icon>
                          <span>优惠券管理</span>
                        </template>
                        <el-menu-item-group>
                          <el-menu-item index="8-1"  :route="{name:'addCoupon'}">上新优惠券</el-menu-item>
                        </el-menu-item-group>
                    </el-sub-menu>
                </el-menu>
              </el-col>
            </el-row>
          </el-aside>
          <el-container>
            <el-main class="main">
              <router-view></router-view>
            </el-main>
            <el-footer class="footer">{{ newTime }}</el-footer>
          </el-container>
        </el-container>
      </el-container>
    </div>
  </template>
  
  <script>
  import {
    Monitor, 
    Odometer, 
    Goods,
    User,
    ChatSquare,
    Box,
    Shop,
    List,
    Finished,
    Avatar
  } 
  from "@element-plus/icons-vue"

  export default {
    
    name: "myIndex",
    components:{
      Monitor,
      Odometer,
      Goods,
      User,
      ChatSquare,
      Box,
      Shop,
      List,
      Finished,
      Avatar
    },
    inject: ['globalStore'], // 注入globalStore
    data(){
      return{
        adminName:"",
        newTime:"",
        adminLevel:999,
         // 初始化数据属性
        user: '',
        name: '',
        identity: '',
        permissions:""
      }
    },
    created() {
      // 在组件创建后，使用注入的全局状态初始化数据属性
      this.user = localStorage.getItem('user');
      this.name = localStorage.getItem('name');
      this.identity = localStorage.getItem('identity');
      this.permissions=JSON.parse(localStorage.getItem('permissions') || '{}')
      console.log("打印",typeof(this.permissions),this.permissions);
    },
    mounted(){
      this.getNowTime();//进入页面调用该方法获取当前时间
      clearInterval(myTimeDisplay );//销毁之前定时器
      var myTimeDisplay = setInterval(() => {
          this.getNowTime(); //每秒更新一次时间
      }, 1000);    
    },
    methods:{
      backIndex(){
        this.$router.push({ name: 'home' });
        this.page=1
      },
      exit(){
        localStorage.removeItem('user'); // 删除 'user' 缓存
        localStorage.removeItem('identity'); // 删除 'identity' 缓存
        localStorage.removeItem('name'); // 删除 'identity' 缓存
        localStorage.removeItem('permissions');
        this.$router.push({ name: 'login' });
      },
      getNowTime() {
        var date = new Date();
        var time = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
        this.newTime = time;
      },
      addZero(s) {
          return s < 10 ? ('0' + s) : s;
      },
    }
  };
  </script>
  
  <style scoped>
  .main{
    background-color: rgb(233, 233, 233);
    width: 100%;
    height: 80vh;
    white-space: nowrap; 
    overflow-y: scroll; 
    overflow-x: hidden;
  }
  .frame-container {
    height: 100vh;
  }
  .header {
    height: 60px;
    background: rgb(0, 109, 187);
    display: flex;
  }
  .header .brand {
    width: 200px;
    margin-left: -20px;
    background-color: #2775b6;
    font-size: 20px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header .header-content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    color: #fff;
  }
  
  .header-content {
      cursor: pointer;
    }
  
  .aside {
    background-color: #506173;
  }
  
  .aside .el-menu .is-active {
    background-color: #434a50 !important;
  }
  
  .footer {
    background: rgb(123, 123, 123);
    color: white;
    text-align: center;
    font-size: 20px;
    padding: 20px;
  }
  </style>
  
  <style scoped>
  .el-menu{
    border-right: none;
  }
  </style>
  
  <style>
  * {
    margin: 0;
    padding: 0;
    border: 0;
    text-decoration: none;
    vertical-align: baseline;
  }
  .yuan{
    border-radius: 10px;
  }
  </style>