/*
 * @Author: xpaibiancheng 486957763@qq.com
 * @Date: 2024-01-30 11:14:31
 * @LastEditors: xpaibiancheng 486957763@qq.com
 * @LastEditTime: 2024-03-15 09:12:21
 * @FilePath: \sjgj_Vue\src\router.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHashHistory } from "vue-router";
import myHome from "@/pages/myHome.vue"
import myBanner from "@/pages/myBanner.vue"
import myComment from "@/pages/myComment.vue"
import myPost from "@/pages/myPost.vue"
import myUser from "@/pages/myUser.vue"
import myDetail from "@/pages/myDetail.vue"
import mySoldDetail from "@/pages/myFinishDetail.vue"
import myIndex from "@/pages/myIndex.vue"
import myLogin from "@/pages/myLogin.vue"
import myAdmin from "@/pages/myAdmin.vue"
import myPlatform from "@/pages/myPlatform.vue"
import myregister from "@/pages/myregister.vue"
import myPayment from "@/pages/myPayment.vue"
import AddShop from "@/pages/addShop.vue"
import AddShopClass from "@/pages/addShopClass.vue"
import unsend from "@/pages/unsend.vue"
import sending from "@/pages/sending.vue"
import sent from '@/pages/sent.vue'
import reFunding from '@/pages/refunding.vue'
import reFunded from '@/pages/refunded.vue'
import reFundPass from'@/pages/refundPass.vue'
import reFundingPass from '@/pages/redundingPass.vue'
import reFundedPass from '@/pages/refundedPass.vue'


import reFundingVip from'@/pages/refundingVip.vue'
import reFundedVip from '@/pages/refundedVip.vue'
import reFundvipcomp from '@/pages/refundvipcomp.vue'

import vipNewOrder from '@/pages/vipneworder.vue'
import vipOldOrder from '@/pages/vipoldorder.vue'


import addAdmin from '@/pages/addadmin.vue'

import mgAdmin from '@/pages/mgadmin.vue'

import updateMgUser from '@/pages/updatemguser.vue'

import addCoupon from '@/pages/addCoupon.vue'

import addShopMg from '@/pages/addShopMg.vue'

const routes = [
    {
        path: '/index',
        component: myIndex,
        name:'index',
        children:[
            {
                path:"home", component:myHome, name: "home"
            },{
                path:"banner", component:myBanner, name: "banner"
            },{
                path:"comment", component:myComment, name: "comment"
            },{
                path:"post", component:myPost, name: "post"
            },{
                path:"user", component:myUser, name: "user"
            },{
                path:"detail", component:myDetail, name: "detail"
            },{
                path:"solddetail", component:mySoldDetail, name: "solddetail"
            },{
                path:"admin", component:myAdmin, name: "admin"
            },
            {
                path:"platform", component:myPlatform, name: "platform"
            },
            {
                path:"register", component:myregister, name: "register"
            },
            {
                path:"payment", component:myPayment, name: "payment"
            },{
                path:"addshop", component:AddShop, name: "AddShop"
            },{
                path:"addShopClass", component:AddShopClass, name: "AddShopClass"
            },{
                path:"unsend", component:unsend, name: "unsend"
            },{
                path:"sending", component:sending, name: "sending"
            },{
                path:"sent", component:sent, name: "sent"
            },{
                path:"reFunding", component:reFunding, name: "reFunding"
            },{
                path:"reFunded", component:reFunded, name: "reFunded"
            },{
                path:"reFundPass", component:reFundPass, name: "reFundPass"
            },{
                path:"reFundingPass", component:reFundingPass, name: "reFundingPass"
            },{
                path:"reFundedPass", component:reFundedPass, name: "reFundedPass"
            },{
                path:"reFundingVip", component:reFundingVip, name: "reFundingVip"
            },{
                path:"reFundedVip", component:reFundedVip, name: "reFundedVip"
            },{
                path:"reFundvipcomp", component:reFundvipcomp, name: "reFundvipcomp"
            },{
                path:"vipNewOrder", component:vipNewOrder, name: "vipNewOrder"
            },{
                path:"vipOldOrder", component:vipOldOrder, name: "vipOldOrder"
            },{
                path:"addAdmin", component:addAdmin, name: "addAdmin"
            },{
                path:"mgAdmin", component:mgAdmin, name: "mgAdmin"
            },{
                path:"updateMgUser", component:updateMgUser, name: "updateMgUser"
            },{
                path:"addCoupon", component:addCoupon, name: "addCoupon"
            },{
                path:"addShopMg", component:addShopMg, name: "addShopMg"
            }    
        ]
    },{
        
        path:"/login", component:myLogin, name: "login"
    }
];

const router = createRouter({
    // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
    history: createWebHashHistory(),
    routes, // `routes: routes` 的缩写
});

export default router;