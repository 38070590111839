<template>
    <div id="post" style="min-width: 0; || width: 0">
      <el-header  style="padding-left:0;display: flex;flex-direction: row;justify-content: space-between;">
              <div style="width:500px;font-size: 25px;">
                  基地会员用户秒小点商品待处理
              </div>
              <div>
                 <el-button type="primary" @click="printSelected">立即打单</el-button>
              </div>
      </el-header>
      <el-table
      :data="goods"
      style="width: 100%;text-align: center;"
      :default-sort = "{prop: 'date', order: 'descending'}"
      height="710"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="title" label="商品标题" width="150" align="center"></el-table-column>
      <el-table-column  prop="tiktokName" label="抖音店铺名" width="150" align="center"></el-table-column>
      <el-table-column  prop="address" label="抖音单号" width="150" align="center"></el-table-column>
      <el-table-column sortable prop="num" label="下单数量" width="100" align="center"></el-table-column>
      <el-table-column  prop="ordertuple" label="下单属性" width="150" align="center"></el-table-column>
      <el-table-column  prop="remarks" label="客户备注" width="150" align="center"></el-table-column>
      <el-table-column  prop="name" label="下单姓名" width="150" align="center"></el-table-column>
      <el-table-column  prop="phone" label="手机号" width="150" align="center"></el-table-column>
      <el-table-column sortable prop="time" label="下单时间" width="100" align="center">
        <template #default="{ row }">
          {{ formatDate(row.time) }}
        </template>
      </el-table-column>
      <el-table-column  prop="price" label="实付价格" width="100" align="center"></el-table-column>
      <el-table-column  prop="ordercode" label="订单编号" width="100" align="center"></el-table-column>
      <el-table-column  prop="id" label="商品编号" width="100" align="center"></el-table-column>
      <el-table-column sortable label="图片" width="260" align="center">
        <template #default="{ row }">
          <img :src="`https://sjgj.xpwangluo.cn/static/${row.image}`" alt="Image" height="110" width="110" @click="showImg(`https://sjgj.xpwangluo.cn/static/${row.image}`)">
        </template>
      </el-table-column>
    </el-table>
    </div>
    <el-dialog v-model="dialogVisibleOut" width="650px">
      <img w-full :src="dialogImageUrlOut" width="100%" alt="预览" />
    </el-dialog>
  </template>
  
  <script>
  import {Plus, CirclePlus, Download, Delete} from "@element-plus/icons-vue"
  import { ElMessageBox ,ElLoading} from 'element-plus'
  import request from "../utils/request"
  export default {
    name: "myPost",
    components:{
      Plus,
      CirclePlus,
      Download,
      Delete
    },
    data() {
      return {
        selectedItems: [], // 存储选中的商品
        goods:[],
        dialogImageUrl: '',
        dialogVisible: false,
        disabled: false,
        dialogImageUrlOut:'',
        dialogVisibleOut:false,
        dialogVisibleForShipping: false,
        selectedCourier: '',
        trackingNumber: '',
        shippingItem: {}, // 用于存储当前操作的发货商品信息
      };
    },
    methods: {
      formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1; // getUTCMonth() 返回的月份是从 0 开始的
        const day = date.getUTCDate();
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      },
      getCurrentTime() {
        //获取当前时间并打印
        var _this = this;
        let yy = new Date().getFullYear();
        let mm = new Date().getMonth()+1;
        let dd = new Date().getDate();
        let hh = new Date().getHours();
        let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
        let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
        _this.gettime = yy+'/'+mm+'/'+dd+' '+hh+':'+mf+':'+ss;
        console.log(_this.gettime)  
        this.nowtime = _this.gettime
      },
      
      
      showImg(src){
        this.dialogImageUrlOut=src,
        this.dialogVisibleOut=true
      },
      handlePictureCardPreview(file) {
          this.dialogImageUrl = file.url;
          this.dialogVisible = true;
      },
      getSvip(){
        // 显示加载提示
        const loading = ElLoading.service({
          lock: true,
          text: '正在添加...',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        request({
            method:"GET",
            url:"/admin/unsendgoodsvip",
        }).then((res)=>{
            console.log(res);
            // 关闭加载提示
            loading.close();
            if(res.data.status=="200"){
                this.goods=res.data.msg
            }else{
                alert("服务器错误")
            }
        })   
      },
      handleSelectionChange(val) {
      this.selectedItems = val;
    },
    printSelected() {
      if (this.selectedItems.length === 0) {
        this.$message.error('请至少选择一个商品');
        return;
      }
      // 直接将选中的商品ID发送到后端
      this.sendSelectedIdsToServer(this.selectedItems.map(item => item.id));
    },
    sendSelectedIdsToServer(selectedIds) {
      console.log(selectedIds);
        request({
            url:"/admin/printgorderinfo",
            method:"POST",
            data:{
                ids: selectedIds
            },
        }).then((res)=>{
            console.log(res);
            if(res.data.status=="200"){
                ElMessageBox.alert(`文件生成地址点击下载:https://24lunwen-1313169354.cos.ap-nanjing.myqcloud.com/${res.data.filename}`, '成功', {
                    confirmButtonText: '确定',
                    callback:action=>{
                        this.downloadFile(`https://24lunwen-1313169354.cos.ap-nanjing.myqcloud.com/${res.data.filename}`,res.data.filename);
                        this.getSvip()
                    }
                });
            }else{
                alert("服务器错误")
            }
        })   
    },
    downloadFile(urlTemp,filename) {
        const url = urlTemp; // 文件URL
        // 创建一个<a>元素并模拟点击进行下载
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); // 这里可以指定下载文件的名称，例如 link.setAttribute('download', 'orders_info.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    },
    mounted:function(){
        this.getSvip()
    },
  };
  </script>
  
  <style scoped>
   .upload{
      width: 800px;
    }
  
    .demo-tabs > .el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
  }
  
    .el-tabs--right .el-tabs__content,
    .el-tabs--left .el-tabs__content {
      height: 100%;
  }
  
  .money {
    font-size: 20px;
    color: #fb1515;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .button {
    padding: 0;
    min-height: auto;
  }
  
  .image {
    width: 100%;
    display: block;
  }
  </style>
  