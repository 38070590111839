<template>
    <div id="post" style="min-width: 0; || width: 0">
      <el-header  style="padding-left:0;display: flex;flex-direction: row;justify-content: space-between;">
              <div style="width:500px;font-size: 25px;">
                  秒小点基地会员商品已处理
              </div>
              <div style="width: 450px;display: flex;flex-direction: row;justify-content: space-between;">
                 
              </div>
      </el-header>
      <el-table
      :data="goods"
      style="width: 100%;text-align: center;"
      :default-sort = "{prop: 'date', order: 'descending'}"
      height="710"
      @selection-change="handleSelectionChange">
      <el-table-column sortable prop="time" label="下单时间" width="200" align="center">
        <template #default="{ row }">
          {{ formatDate(row.time) }}
        </template>
      </el-table-column>
      <el-table-column prop="title" label="商品标题" width="150" align="center"></el-table-column>
      <el-table-column  prop="ordercode" label="订单编号" width="100" align="center"></el-table-column>
      <el-table-column sortable prop="num" label="下单数量" width="100" align="center"></el-table-column>
      <el-table-column  prop="ordertuple" label="下单属性" width="150" align="center"></el-table-column>
      <el-table-column  prop="name" label="下单姓名" width="150" align="center"></el-table-column>
      <el-table-column  prop="address" label="下单地址" width="150" align="center"></el-table-column>
      <el-table-column  prop="phone" label="手机号" width="150" align="center"></el-table-column>
      <el-table-column  prop="remarks" label="客户备注" width="150" align="center"></el-table-column>
      <el-table-column  prop="price" label="实付价格" width="100" align="center"></el-table-column>
      <el-table-column  prop="id" label="商品编号" width="100" align="center"></el-table-column>
      <el-table-column sortable label="图片" width="260" align="center">
        <template #default="{ row }">
          <img :src="`https://sjgj.xpwangluo.cn/static/${row.image}`" alt="Image" height="110" width="110" @click="showImg(`https://sjgj.xpwangluo.cn/static/${row.image}`)">
        </template>
      </el-table-column>
    </el-table>
    </div>
    <el-dialog v-model="dialogVisibleOut" width="650px">
      <img w-full :src="dialogImageUrlOut" width="100%" alt="预览" />
    </el-dialog>
  </template>
  
  <script>
  import {Plus, CirclePlus, Download, Delete} from "@element-plus/icons-vue"
  import {ElLoading} from 'element-plus'
  import request from "../utils/request"
  import moment from 'moment';
  export default {
    name: "myPost",
    components:{
      Plus,
      CirclePlus,
      Download,
      Delete
    },
    data() {
      return {
        goods:[],
        dialogImageUrl: '',
        dialogVisible: false,
        disabled: false,
        dialogImageUrlOut:'',
        dialogVisibleOut:false,
        logisticInfo: [], // 存储物流信息
        logisticDialogVisible: false, // 控制物流信息弹窗的显示
      };
    },
    methods: {
      formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1; // getUTCMonth() 返回的月份是从 0 开始的
        const day = date.getUTCDate();
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      },
      handleSelectionChange(selection) {
        this.multipleSelection = selection;
      },

      openChangeWindow(index,row) {
        console.log(index,row);
        console.log("快递方式",row.kuaidistyle,"快递单号",row.kuaidicode);
        request({
            method:"POST",
            url:"/logtraject",
            data:{
                kuaidistyle:row.kuaidistyle,
                kuaidicode:row.kuaidicode
            }
            }).then((res)=>{
                console.log("物流信息",res);
                this.logisticInfo=res.data.msg
                this.logisticDialogVisible=true
            })   
      },
      
      delOne($index,row){
        this.$confirm('确认删除商品吗？',"删除商品",{
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(()=>{
          this.$notify({
            title: '',
            message: '已删除',
            type: 'warning'
          });
        this.goods = this.goods.filter((item) => item !== row);
        })
      },
      getCurrentTime() {
        //获取当前时间并打印
        var _this = this;
        let yy = new Date().getFullYear();
        let mm = new Date().getMonth()+1;
        let dd = new Date().getDate();
        let hh = new Date().getHours();
        let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
        let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
        _this.gettime = yy+'/'+mm+'/'+dd+' '+hh+':'+mf+':'+ss;
        console.log(_this.gettime)  
        this.nowtime = _this.gettime
      },
      handleRemove(file) {
          console.log(file);
      },
      handleDownload(file) {
        console.log(file);
      },
      showImg(src){
        this.dialogImageUrlOut=src,
        this.dialogVisibleOut=true
      },
      handlePictureCardPreview(file) {
          this.dialogImageUrl = file.url;
          this.dialogVisible = true;
      },
      getSvip(){
        const loading = ElLoading.service({
          lock: true,
          text: '正在添加...',
        });
        request({
            method:"GET",
            url:"/admin/sendinggoodsvip",   
        }).then((res)=>{
            loading.close();
            console.log(res);
            if(res.data.status=="200"){
                this.goods=res.data.msg
            }else{
                alert("服务器错误")
            }
        })   
      },
    },
    mounted:function(){
          this.getSvip()
    },
  };
  </script>
  
  <style scoped>
   .upload{
      width: 800px;
    }
  
    .demo-tabs > .el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
  }
  
    .el-tabs--right .el-tabs__content,
    .el-tabs--left .el-tabs__content {
      height: 100%;
  }
  
  .money {
    font-size: 20px;
    color: #fb1515;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .button {
    padding: 0;
    min-height: auto;
  }
  
  .image {
    width: 100%;
    display: block;
  }
  </style>
  