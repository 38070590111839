<template>
    <div class="add-shop-container">
      <div class="add-shop">
        <h1>上传商品</h1>
        <form @submit.prevent="submitForm">
          <!-- 商品昵称、描述等字段 -->
          <!-- 商品分类 -->
          <div class="form-group">
            <label for="category">商品分类:</label>
            <select style="width: 82%;" class="form-control" id="category" v-model="product.category" required>
              <option disabled value="">请选择分类</option>
              <option v-for="category in categories" :key="category" :value="category">{{ category }}</option>
            </select>
          </div>
          <!-- 商品昵称 -->
          <div class="form-group">
            <label for="name">商品昵称:</label>
            <input class="form-control" id="name" v-model="product.name" type="text" required>
          </div>
          <!-- 商品描述 -->
          <div class="form-group">
            <label for="description">商品描述:</label>
            <textarea class="form-control" id="description" v-model="product.description"></textarea>
          </div>  
          <!-- 动态属性规格与子属性输入 -->
          <div class="form-selectAll">
            <label>商品属性规格:</label>
            <div v-for="(spec, specIndex) in product.specs" :key="`spec-${specIndex}`" class="spec-group">
              <input class="form-control" :value="spec.name" @input="updateSpecName(specIndex, $event.target.value)" placeholder="属性名称" type="text">
              <button class="btn remove-btn" @click="removeSpec(specIndex)">删除属性</button>
              <div v-for="(value, valueIndex) in spec.values" :key="`value-${specIndex}-${valueIndex}`" class="value-group">
                <input class="form-control" :value="value" @input="updateSpecValue(specIndex, valueIndex, $event.target.value)" placeholder="属性值" type="text">
                <button class="btn remove-btn" @click="removeSpecValue(specIndex, valueIndex)">删除属性值</button>
              </div>
              <button type="button" class="btn add-btn" @click="addSpecValue(specIndex)">添加属性值</button>
            </div>
            <button type="button" class="btn add-btn" style="width: 89%;" @click="addSpec">添加商品属性规格</button>
          </div>
          <!-- 根据属性组合动态生成的价格和库存输入 -->
          <div v-if="combinations.length">
            <div v-for="(combination, index) in combinations" :key="`combination-${index}`">
              <h3>{{ combination.name }}</h3>
              <input class="form-price" v-model="combination.memberPrice" placeholder="会员价格" type="text">
              <input style="margin-left: 20px;margin-right: 20px;" class="form-price" v-model="combination.normalPrice" placeholder="普通价格" type="text">
              <input class="form-price" v-model="combination.stock" placeholder="库存量" type="text">
            </div>
          </div>
          <div v-else>
            <input class="form-price" v-model="product.prices.memberPrice" placeholder="会员价格" type="text">
            <input style="margin-left: 20px;margin-right: 20px;" class="form-price" v-model="product.prices.normalPrice" placeholder="普通价格" type="text">
            <input class="form-price" v-model="product.prices.stock" placeholder="库存量" type="text">
          </div>
          <!-- 图片上传控件 -->
          <div class="yijianAdd" v-if="product.specs.length>0">
            <div class="yijianAddRows" @click="yijianAddBind">一键填充</div>
          </div>
        <div class="upload-container" style="margin-top: 30px;">
            <div style="padding-bottom: 20px;">上传商品图片</div>
            <input type="file" multiple @change="handleFiles" accept="image/*">
        </div>
        <!-- 图片预览区域 -->
        <div class="image-preview-container" style="margin-top: 30px;">
            <div v-for="(image, index) in images" :key="index" class="image-preview">
                <img :src="image.url" :alt="`Image ${index + 1}`">
                <button @click="removeImage(index)" style="color: red;">删除</button>
            </div>
        </div>
          <!-- 售后及服务 -->
          <div class="form-group">
            <label for="service">售后及服务:</label>
            <textarea class="form-control" id="service" v-model="product.service"></textarea>
          </div>
  
          <!-- 商品详情说明 -->
          <div class="form-group">
            <label for="details">商品详情说明:</label>
            <textarea class="form-control" id="details" v-model="product.details"></textarea>
          </div>
          <button type="submit" class="btn submit-btn" style="width: 90%;">上传商品</button>
        </form>
      </div>
    </div>
  </template>

  <style scoped>
  .add-shop-container {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  .add-shop {
    width: 95%;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  .form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    
  }
  .form-selectAll{
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  .form-control {
    width: 80%;
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    margin-left: 10px;
  }
  .form-price{
    width:26.7%;
    padding: 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 5px;
    
  }
  
  .form-control:focus {
    border-color: #4d90fe;
    outline: none;
  }
  .btn {
    margin-top: 10px;
    padding: 6px 12px;
    background-color: #4d90fe;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .btn:hover {
    background-color: #357abd;
  }
  .remove-btn {
    background-color: #ff4d4f;
  }
  .remove-btn:hover {
    background-color: #d11a2a;
  }
  .add-btn {
    background-color: #52c41a;
  }
  .add-btn:hover {
    background-color: #3fa016;
  }
  .submit-btn {
    width: 100%;
    padding: 10px 0;
  }
  .image-preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* 调整为你希望的间距 */
}
.image-preview img {
  width: 100px; /* 调整为你希望的尺寸 */
  height: 100px;
  object-fit: cover;
}
.yijianAdd{
  width: 90%;
  margin-top: 20px;
}
.yijianAddRows{
  width: 99.5%;
  height: 40px;
  background-color: #4d90fe;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 5px;
}
  </style>
  


  

  
  <script>
import request from "../utils/request"
import { ElNotification } from 'element-plus'
export default {
  name: "AddShopFlag05", // 更新为flag05
  data() {
    return {
      product: {
        name: '',
        description: '',
        specs: [],
        category: '',
        service: '',
        details: '',
        prices: {
          memberPrice: '',
          normalPrice: '',
          stock: ''
        }
      },
      categories: ["衣物", "鞋靴", "美妆"],
      combinations: [],
      images: [], // 存储选中的图片信息
      imagesSend:[]
    };
  },
  computed: {
    generateCombinations() {
      if (!this.product.specs.length) return [];
      let result = [[]];
      this.product.specs.forEach(spec => {
        let temp = [];
        spec.values.forEach(value => {
          result.forEach(old => {
            temp.push([...old, `${spec.name}:${value}`]);
          });
        });
        result = temp;
      });
      return result.map(combination => ({
        name: combination.join(" + "),
        memberPrice: '',
        normalPrice: '',
        stock: ''
      }));
    }
  },
  watch: {
    'product.specs': {
      handler() {
        this.combinations = this.generateCombinations;
      },
      deep: true,
    }
  },
  methods: {
    addSpec() {
      this.product.specs.push({ name: '', values: [] });
    },
    removeSpec(index) {
      this.product.specs.splice(index, 1);
    },
    addSpecValue(specIndex) {
      this.product.specs[specIndex].values.push('');
    },
    removeSpecValue(specIndex, valueIndex) {
      this.product.specs[specIndex].values.splice(valueIndex, 1);
    },
    updateSpecName(specIndex, value) {
      this.product.specs[specIndex].name = value;
    },
    updateSpecValue(specIndex, valueIndex, value) {
      this.product.specs[specIndex].values[valueIndex] = value;
    },
    /* 上传商品图片 */
    handleFiles(event) {
      const files = Array.from(event.target.files);
      // 限制图片数量
      const filesToAdd = files.slice(0, 6 - this.images.length);
      filesToAdd.forEach(file => {
        const reader = new FileReader();
        reader.onload = e => {
          this.images.push({ url: e.target.result, file });
        };
        reader.readAsDataURL(file);
      });
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },
    submitForm() {
        console.log("提交的商品信息:", this.product, this.combinations);
        // 提交表单逻辑
        this.uploadImages().then(imageNames => {
            // 图片上传成功，接着上传商品信息，包括图片名称
            this.uploadProductInfo(imageNames);
        }).catch(error => {
            alert("图片上传失败：" + error);
        });
    },
    getSvip(){
        request({
            method:"GET",
            url:"/admin/get_shopClass",
            }).then((res)=>{
                console.log(res);
                if(res.data.status==200){
                    var responseData=res.data.msg
                    var arrayTemp=[]
                    console.log("分类",responseData);
                    for(var i=0;i<responseData.length;i++){
                        arrayTemp.push(responseData[i].name)
                        this.categories=arrayTemp
                    }

                }
            })          
    },
    uploadImages() {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      this.images.forEach((image, index) => {
        formData.append(`file${index}`, image.file);
      });
      // 替换为你的图片上传接口
      fetch('https://sjgj.xpwangluo.cn/admin/images', {
        method: 'POST',
        body: formData,
      })
      .then(response => response.json())
      .then(data => {
        console.log("图片地址",data);
        var imagesTemp=[]
        if(data.status === 'success') {
          this.imagesSend = data.imageNames; // 直接赋值
          console.log("赋值",this.imagesSend);
          resolve(data.imageNames); // 假设后端返回的字段中包含imageNames数组
        } else {
          reject(data.message);
        }
      })
      .catch(error => {
        console.error('上传图片失败:', error);
        reject(error);
      });
    });
    },
    uploadProductInfo(imageNames) {
    let specsInfo;
    if (this.product.specs.length === 0) {
      // 没有规格属性的情况
      specsInfo = [{
        memberPrice: this.product.prices.memberPrice,
        normalPrice: this.product.prices.normalPrice,
        stock: this.product.prices.stock,
        hasSpecs: false,
        specsCombination: [], // 没有规格属性时为空数组
      }];
    } else {
      // 有规格属性的情况
      specsInfo = this.combinations.map(combination => ({
        memberPrice: combination.memberPrice,
        normalPrice: combination.normalPrice,
        stock: combination.stock,
        hasSpecs: true,
        specsCombination: combination.name.split(" + ").map(spec => spec.split(":")[1]), // 只保留属性值
      }));
    }
     // 构建规格型号的数据项
    const specsModel = this.product.specs.map(spec => ({
        name: spec.name,
        value: spec.values,
    }));
    const productInfo = {
      category: this.product.category,
      name: this.product.name,
      description: this.product.description,
      specsInfo,
      specsModel, // 添加规格型号的数据项
      images: imageNames,
      service: this.product.service,
      details: this.product.details,
    };
    // 这里是发送到后端的代码，与之前相同
    fetch('https://sjgj.xpwangluo.cn/admin/productInfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(productInfo),
    })
    .then(response => response.json())
    .then(data => {
      console.log('商品信息上传成功:', data);
      alert('上传成功');
      // 刷新页面
      this.resetFormData();

    })
    .catch(error => {
      console.error('上传商品信息失败:', error);
    });
    
    },
    
    resetFormData() {
        // 重新初始化data对象中的所有属性
        this.product = {
        name: '',
        description: '',
        specs: [],
        category: '',
        service: '',
        details: '',
        prices: {
            memberPrice: '',
            normalPrice: '',
            stock: ''
        }
        };
        this.categories = ["衣物", "鞋靴", "美妆"];
        this.combinations = [];
        this.images = [];
        this.imagesSend = [];
    },
    //一键填充
    yijianAddBind(){
       this.combinations = this.generateCombinations;
       console.log(this.combinations);
       if(this.combinations.length==0){
        ElNotification({
          title: '警告',
          message: '请输入子属性',
          type: 'warning',
        })
        return
       }else if(this.combinations[0].memberPrice.length==0 || this.combinations[0].normalPrice.length==0 || this.combinations[0].stock.length==0){
        ElNotification({
          title: '警告',
          message: '请确保第一行价格库存填写完整',
          type: 'warning',
        })
        return
       }
       var TempCombinations=this.combinations
       TempCombinations.forEach(element => {
          element.memberPrice=TempCombinations[0].memberPrice,
          element.normalPrice=TempCombinations[0].normalPrice,
          element.stock=TempCombinations[0].stock
       });
       this.combinations=TempCombinations
    }
  },
  
  mounted() {
    this.combinations = this.generateCombinations;
    this.getSvip()
  }
}
</script>

  