<template>
    <div id="comment">
              <el-table :data="shops" style="width: 100%" class="yuan">
                  <el-table-column label="订单号" prop="id" />
                  <el-table-column label="商品名" prop="name" />
                  <el-table-column label="收货信息" prop="phone" />
                  <el-table-column label="收货地址" prop="position" />
                  <el-table-column label="用户类型" prop="type" />
                  <el-table-column label="下单日期" prop="date" />
                  <el-table-column label="订单状态" prop="style" />
                  <el-table-column label="评论内容" prop="cont" />
                  <el-table-column align="right">
                  <template #header>
                    <el-input v-model="search" size="small" placeholder="Type to search" />
                  </template>
                  <template #default="{ $index, row }">
                      <el-button size="middle" @click="handleEdit($index, row)" class="talk">回复</el-button>
                      <!-- <el-button size="small" type="danger" @click="handleDelete($index, row)">取消订单</el-button> -->
                  </template>
                  </el-table-column>
              </el-table>
            <el-pagination
            :page-size="20"
            :pager-count="11"
            layout="prev, pager, next"
            :total="1000"
          />
    </div>
</template>
  
  <script>
  export default {
      name:"myComment",
      data(){
    return{
      shops:[
        {id:100,name:"张牧之",phone:133990,date:"2020年2月6日",type:"学员",position:"山东省临沂市",style:"代发货",styleId:0,cont:"包装完好，纯金属的，大人小孩都爱吃，每次洗衣服都会放"},
        {id:101,name:"王牧之",phone:133990,date:"2020年2月7日",type:"普通客户",position:"山东省临沂市",style:"代发货",styleId:0,cont:"包装完好，纯金属的，大人小孩都爱吃，每次洗衣服都会放"},
        {id:102,name:"李牧之",phone:133990,date:"2020年2月8日",type:"普通客户",position:"山东省临沂市",style:"代发货",styleId:0,cont:"包装完好，纯金属的，大人小孩都爱吃，每次洗衣服都会放"},
        {id:103,name:"刘牧之",phone:133990,date:"2020年2月9日",type:"普通客户",position:"山东省临沂市",style:"代发货",styleId:0,cont:"包装完好，纯金属的，大人小孩都爱吃，每次洗衣服都会放"},
        {id:104,name:"宋牧之",phone:133990,date:"2020年2月10日",type:"普通客户",position:"山东省临沂市",style:"代发货",styleId:0,cont:"包装完好，纯金属的，大人小孩都爱吃，每次洗衣服都会放"},
        {id:105,name:"秦牧之",phone:133990,date:"2020年2月11日",type:"普通客户",position:"山东省临沂市",style:"代发货",styleId:0,cont:"包装完好，纯金属的，大人小孩都爱吃，每次洗衣服都会放"},
      ]
    }
  }
  }
  </script>
  
  <style scoped>
  .talk{
    margin-right: 20px;
  }
  </style>