<template>
    <div id="finishdetail">
      <el-header  style="padding-left:0;display: flex;flex-direction: row;justify-content: space-between;">
              <div style="width:500px">
                  <el-input v-model="search" size="millde" placeholder="搜索" style="width: 300px;margin-right: 10px;"/>
                  <el-button type="primary">搜索</el-button>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-between;" v-if="type!=0">
                  <div><el-button type="danger" @click="dele">删除订单</el-button></div>
              </div>
      </el-header>
      <el-table :data="shops" style="width: 100%" class="yuan" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="80" align="center"></el-table-column>
        <el-table-column sortable label="订单号" width="200" prop="id" align="center"/>
        <el-table-column sortable label="商品名" prop="name" width="200" align="center"/>
        <el-table-column sortable label="收货信息" prop="phone" width="200" align="center"/>
        <el-table-column sortable label="收货地址" prop="position" width="300" align="center"/>
        <el-table-column sortable label="用户类型" prop="type" width="200" align="center"/>
        <el-table-column sortable label="下单日期" prop="date" width="250" align="center"/>
        <el-table-column sortable label="订单状态" width="220" align="center">
          <template #default="{ row }">
            <div style="position: relative;">
              <div v-if="row.styleId==9" style="text-align: center;background-color: #00ff2a;position: relative;color: white;">已完成<span style="white-space: pre;">&nbsp;&nbsp;&nbsp;</span></div>
              <div v-if="row.styleId==-1" style="text-align: center;background-color: #ff0000;color: white;position: relative">已取消<span style="white-space: pre;">&nbsp;&nbsp;&nbsp;</span></div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </template>
  
  
  
  <script>
  export default {
    name: 'myFinishDetail',
    props: {
      tableData: Array,
    },
    data(){
      return{
        shops:[
          {id:100,name:"张牧之",phone:133990,date:"2020年2月6日",type:"学员",position:"山东省临沂市",styleId:9},
          {id:101,name:"王牧之",phone:133990,date:"2020年2月7日",type:"普通客户",position:"山东省临沂市",styleId:9},
          {id:101,name:"王牧之",phone:133990,date:"2020年2月7日",type:"普通客户",position:"山东省临沂市",styleId:9},
          {id:101,name:"王牧之",phone:133990,date:"2020年2月7日",type:"普通客户",position:"山东省临沂市",styleId:-1},
          
        ],
        multipleSelection:[],
        type:0
      }
    },
    methods:{
      handleSelectionChange(selection) {
        this.multipleSelection = selection;
      },
      dele(){
        this.$confirm('此操作将会删除所选的订单!!!,删除后不可恢复!!, 是否继续?', '警告⚠⚠⚠⚠⚠', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(() => {
          this.$notify({
          title: '!',
          message: '订单已被删除',
          type: 'warning'
        });
          this.shops = this.shops.filter((item) => !this.multipleSelection.includes(item));
        }).catch(() => {
                  
        });

      }
    }
  };
  </script>
  
  <style scoped>
  /* HTML: <div class="loader"></div> */
  .loader {
    width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #000;
    box-shadow: 0 0 0 0 #0004;
    animation: l2 1.5s infinite linear;
    position: relative;
  }
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 #0004;
    animation: inherit;
    animation-delay: -0.5s;
  }
  .loader:after {
    animation-delay: -1s;
  }
  @keyframes l2 {
      100% {box-shadow: 0 0 0 40px #0000}
  }
  </style>
  