<template>
<div id="home">
  <!-- <el-calendar v-model="value"/> -->
  <el-header height="330px">
    <div class="data">
        <div style="font-size: 30px;padding:3px 0px 3px 10px;margin-bottom: 10px;">今日数据</div>
        <div style="display: flex;flex-direction: row;justify-content: space-between;">
              <div class="showbox">
                    <div class="title">今 日 销 售 额</div>
                    <div class="num">{{ sales  }}</div>
                    <div class="title">比昨天{{ sales_than }}<el-icon v-if="sales_than>0" color="green"><CaretTop /></el-icon><el-icon v-if="sales_than<0" color="red"><CaretBottom /></el-icon></div>
              </div>
              <div class="showbox">
                    <div class="title">今 日 订 单 量</div>
                    <div class="num">{{ detail_num  }}</div>
                    <div class="title">比昨天{{ detail_num_than }}<el-icon v-if="detail_num_than>0" color="green"><CaretTop /></el-icon><el-icon v-if="detail_num_than<0" color="red"><CaretBottom /></el-icon></div>
              </div>
              <div class="showbox">
                    <div class="title">新 增 普 通 会 员</div>
                    <div class="num">{{ new_user  }}</div>
                    <div class="title">比昨天{{ new_user_than }}<el-icon v-if="new_user_than>0" color="green"><CaretTop /></el-icon><el-icon v-if="new_user_than<0" color="red"><CaretBottom /></el-icon></div>
              </div>
              <div class="showbox">
                    <div class="title">新 增 学 员 会 员</div>
                    <div class="num">{{ new_vip  }}</div>
                    <div class="title">比昨天{{ new_vip_than }}<el-icon v-if="new_vip_than>0" color="green"><CaretTop /></el-icon><el-icon v-if="new_vip_than<0" color="red"><CaretBottom /></el-icon></div>
              </div>
        </div>
    </div>
  </el-header>
  <el-main>
    <el-container>
        <div id="liu" class="excel"></div>
      <el-main class="log">
        <div style="font-size: 30px;padding:0 5px 10px 5px;">历史公告</div>
        <div>
          <el-timeline>
            <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            :icon="activity.icon"
            :type="activity.type"
            :color="activity.color"
            :size="activity.size"
            :hollow="activity.hollow"
            :timestamp="activity.timestamp"
            >
            {{ activity.content }}
          </el-timeline-item>
        </el-timeline>
        </div>
      </el-main>
    </el-container>
  </el-main>
</div>
</template>
<script>
import * as echarts from 'echarts';
import { CaretBottom, CaretTop, MoreFilled } from '@element-plus/icons-vue'


export default {
  
  name: "myHome",
  components:{
    CaretBottom,
    CaretTop,
  },
  data() {
    return {
      sales: 1200,
      detail_num: 300,
      new_user: 20,
      new_vip: 30,

      new_vip_than: -20,
      detail_num_than: -20,
      new_user_than: 10,
      sales_than: 30,

      Lsales:1170,
      Ldetail_num: 290,
      Lnew_user: 30,
      Lnew_vip: 90,
      activities : [
      {
        content: 'Custom icon',
        timestamp: '2018-04-12 20:46',
        size: 'large',
        type: 'primary',
        icon: MoreFilled,
        color:'#0bbd87'
      },
      {
        content: 'Custom color',
        timestamp: '2018-04-03 20:46',
        color: '#0bbd87',
      },
      {
        content: 'Custom hollow',
        timestamp: '2018-04-03 20:46',
        hollow: true,
        color:'#0bbd87'
      },
      {
        content: 'Custom size',
        timestamp: '2018-04-03 20:46',
        // size: 'large',
      },
      {
        content: 'Default node',
        timestamp: '2018-04-03 20:46',
        hollow: true,
      },
    ]
    };
  },
  mounted() {
    var chartDom = document.getElementById('liu');
    var myChart = echarts.init(chartDom);
    var series = [
      {
        data: [120, 200, 150, 80, 70, 110, 130],
        type: 'bar',
        stack: 'a',
        name: 'a'
      },
      {
        data: [10, 46, 64, '-', 0, '-', 0],
        type: 'bar',
        stack: 'a',
        name: 'b'
      },
    ];
    const stackInfo = {};
    for (let i = 0; i < series[0].data.length; ++i) {
      for (let j = 0; j < series.length; ++j) {
        const stackName = series[j].stack;
        if (!stackName) {
          continue;
        }
        if (!stackInfo[stackName]) {
          stackInfo[stackName] = {
            stackStart: [],
            stackEnd: []
          };
        }
        const info = stackInfo[stackName];
        const data = series[j].data[i];
        if (data && data !== '-') {
          if (info.stackStart[i] == null) {
            info.stackStart[i] = j;
          }
          info.stackEnd[i] = j;
        }
      }
    }
    for (let i = 0; i < series.length; ++i) {
      const data = series[i].data;
      const info = stackInfo[series[i].stack];
      for (let j = 0; j < series[i].data.length; ++j) {
        // const isStart = info.stackStart[j] === i;
        const isEnd = info.stackEnd[j] === i;
        const topBorder = isEnd ? 20 : 0;
        const bottomBorder = 0;
        data[j] = {
          value: data[j],
          itemStyle: {
            borderRadius: [topBorder, topBorder, bottomBorder, bottomBorder]
          }
        };
      }
    }
    const option = {
      xAxis: {
        type: 'category',
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      },
      yAxis: {
        type: 'value'
      },
      series: series
    };

    myChart.setOption(option);

  },
  methods:{
    comp(){
      this.sales_than = this.sales - this.Lsales
      this.detail_num_than = this.detail_num - this.Ldetail_num
      this.new_user_than = this.new_user - this.Lnew_user
      this.new_vip_than = this.new_vip - this.Lnew_vip
    },

  },
  /* 提前加载数据 */
  created(){
      //自动加载indexs方法
      this.comp();
  }
}

</script>

<style scoped>
.el-col {
text-align: center;
}
.data{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: 1px solid rgba(125, 125, 125, 0.551); */
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(102, 102, 102, 0.319);
}
.showbox{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  height: 240px;
  align-items: center;
  justify-content: center;
  width: 23%;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(102, 102, 102, 0.495);
}
.title{
  font-size: 20px;
  color: gray;
}
.num{
  font-size: 45px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.excel{
  height: 400px;
  width: 700px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 8px rgba(102, 102, 102, 0.395);
  border-radius: 10px;
}
.log{
  background-color: rgb(255, 255, 255);
  margin-left: 20px;  
  box-shadow: 0 2px 8px rgba(102, 102, 102, 0.384);
  border-radius: 10px;
}
</style>