<template>
  <div id="user">
    <el-header  style="padding-left:0;padding-right: 0;display: flex;flex-direction: row;justify-content: space-between;">
            <div>
                <el-input v-model="search" size="millde" placeholder="搜索用户" style="width: 300px;margin-right: 10px;"/>
                <el-button type="primary">搜索</el-button>
            </div>
    </el-header>
    <el-table :data="user" style="width: 100%" class="yuan">
      <el-table-column align="center" width="120" sortable label="id" prop="id" />
      <el-table-column align="center" sortable width="200" label="用户名" prop="name" />
      <el-table-column sortable label="手机号" width="200" prop="phone" />
        <el-table-column sortable label="省" prop="province" />
        <el-table-column sortable label="市" prop="city" />
        <el-table-column sortable label="区/县" prop="hometwon" />
        <el-table-column label="性别" prop="sex">
          <template #default="{ row }">
            <span v-if="row.sex === 'woman'" style="color: pink;">女</span>
            <span v-else-if="row.sex === 'man'" style="color:dodgerblue;">男</span>
          </template>
        </el-table-column>
        <el-table-column sortable label="注册日期" prop="resTime" />
        <el-table-column sortable label="状态" prop="status"/>
        <el-table-column align="right" label="操作">
        <template #default="{  row }">
          
           <el-popconfirm title="激活此用户？"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="PostActivation(row)"
            >
              <template #reference>
                <el-button size="small" type="success">激活</el-button>
              </template>
            </el-popconfirm>
          <el-popconfirm title="禁用此用户？"
          confirm-button-text="确认"
          cancel-button-text="取消"
          @confirm="PostDisable(row)"
          >
            <template #reference>
              <el-button size="small" type="primary" >禁用</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script >
// import { h,} from 'vue'
// import { ElMessageBox } from 'element-plus'
// import axios from  'axios'
// import { ElNotification } from 'element-plus'
import request from "../utils/request"
export default {
  name: 'myUser',
  data() {
    return {
      user: [],
      drawer: false,
      editingUser: null,
      search:'',
      search2:'',
      // adminLevel:"9"
    };
  },
  methods: {
    getSvip(){
      request({
        method:"GET",
        url:"/admin/user",
      }).then((res)=>{
        console.log(res);
        if(res.data.status==200){
          var responseData=res.data.msg
          this.user=responseData
        }
      })          
    },
    // 删除
    PostDelete(row){
      request({
        method:"POST",
        data:{
          phone:row.phone,
          operate:'1'
        },
        url:"/admin/user_operate",
      }).then((res)=>{
        console.log(res);
        if(res.data.status=="200"){
          this.getSvip()
        }else{
          this.$notify({
            title: '',
            message: '删除失败',
            type: 'warning'
          });
        }
      }) 
    },
    // 激活
    PostActivation(row){
      request({
      method:"POST",
      data:{
        phone:row.phone,
        operate:'3'
      },
      url:"/admin/user_operate",
    }).then((res)=>{
      console.log(res);
      if(res.data.status==200){
        this.getSvip()
      }
    }) 
    },
    // 禁用
    PostDisable(row){
      request({
      method:"POST",
      data:{
        phone:row.phone,
        operate:'2'
      },
      url:"/admin/user_operate",
    }).then((res)=>{
      console.log(res);
      if(res.data.status=="200"){
        this.getSvip()
      }else{
        this.$notify({
          title: '封禁失败',
          message: '请联系维修人员',
          type: 'warning'
        })
      }
    }) 
    },
  },
  mounted:function(){
    this.getSvip()
  },
};

</script>

<style scoped>

</style>
