<template>
  <div id="post" style="min-width: 0; || width: 0">
    <el-header  style="padding-left:0;display: flex;flex-direction: row;justify-content: space-between;">
            <div style="width:500px;font-size: 25px;">
                <!-- <el-input v-model="search" size="millde" placeholder="搜索" style="width: 300px;margin-right: 10px;"/>
                <el-button type="primary">搜索</el-button> -->
                山东世纪管家秒小点商品
            </div>
            <div style="width: 450px;display: flex;flex-direction: row;justify-content: space-between;">
                <!-- <div><el-button type="success" @click="upListItem">批量上架</el-button></div>
                <div><el-button type="info" @click="handleSwitchChange">批量下架</el-button></div>
                <div><el-button type="danger" @click.prevent="deleteItem($index, row)">批量删除</el-button></div> -->
            </div>
    </el-header>
    <el-table
    :data="goods"
    style="width: 100%;text-align: center;"
    :default-sort = "{prop: 'date', order: 'descending'}"
    height="710"
    @selection-change="handleSelectionChange">
    <el-table-column type="selection" width="80" align="center"></el-table-column>
    <el-table-column sortable fixed prop="id" label="商品编号" width="100" align="center"></el-table-column>
    <el-table-column sortable fixed prop="class" label="商品分类" width="100" align="center"></el-table-column>
    <el-table-column sortable prop="name" label="商品标题" width="180" align="center"></el-table-column>
    <el-table-column sortable label="图片" width="260" align="center">
      <template #default="{ row }">
        <img :src="`https://sjgj.xpwangluo.cn/static/${row.images[0]}`" alt="Image" height="110" width="110" @click="showImg(`https://sjgj.xpwangluo.cn/static/${row.images[0]}`)">
      </template>
    </el-table-column>
    <el-table-column sortable label="商品状态" width="160" align="center">
      <template #default="{ row }">
          <div style="position: relative;">
            <div v-show="row.status=='上架'" style="position: relative">在售<span style="white-space: pre;">&nbsp;&nbsp;&nbsp;</span></div>
            <div v-show="row.status=='下架'" style="color: #ff0000;position: relative">已下架<span style="white-space: pre;">&nbsp;&nbsp;&nbsp;</span></div>
          </div>
        </template>
    </el-table-column>
    <!-- <el-table-column sortable prop="saleNum" label="销量" width="100" align="center"></el-table-column> -->
    <el-table-column sortable prop="saleNum" label="销量" width="100" align="center">
      <template #default="{ row }">
        <div @mousedown="handleMouseDown(row, $event)" @mouseup="handleMouseUp" @mouseleave="handleMouseUp">
          {{ row.saleNum }}
        </div>
      </template>
    </el-table-column>
    <el-table-column sortable prop="time" label="日期" width="200" align="center">
      <template #default="{ row }">
        {{ formatDate(row.time) }}
      </template>
    </el-table-column>
    <el-table-column  prop="description" label="描述" width="500" align="center"></el-table-column>
    <el-table-column  prop="detail" label="详情介绍" width="500" align="center"></el-table-column>
    <el-table-column  prop="service" label="承诺" width="160" align="center"></el-table-column>
    <el-table-column sortable fixed="right" label="操作" width="400"  align="center">
      <template #default="{$index, row}">
        <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;padding: 14px;">
            <div>
              <el-button
                @click.prevent="openChangeWindow($index, row)"
                type="primary"
                size="large"
                >
                基本编辑
              </el-button>
            </div>
            <div>
              <el-button
                type="success"
                size="medium"
                @click.prevent="editAttributes(row.id)">
                属性编辑
              </el-button>
            </div>
            <div>
              <el-button
                @click.prevent="downit($index, row)"
                type="info"
                size="medium"
                v-if="row.status=='上架'"
                >
                下架
              </el-button>
              <el-button
                @click.prevent="pulldown($index, row)"
                type="info"
                size="medium"
                v-if="row.status=='下架'"
                >
                上架
              </el-button>
            </div>
            <div>
              <el-button
                type="danger"
                size="medium"
                @click.prevent="delOne($index, row)">
                删除
              </el-button>
            </div>
            
        </div>
      </template>
    </el-table-column>
  </el-table>
    <el-drawer v-model="drawer" :with-header="false">
      <el-header>
            <h1 style="font-size: 30px;">编辑商品信息</h1>
            <div style="margin-top: 10px;">
              <div v-show="!winowType" style="display: flex;justify-content: left: 10px;;padding-left: 80%;padding-right: 10%;">
                <el-button type="success" @click="addGoods">
                    添加
                </el-button>
              </div>
              <div v-show="winowType" style="display: flex;justify-content: space-between;padding-left: 10%;padding-right: 10%;">
                <el-button type="success" @click="upit">
                    上架
                </el-button>
                <el-button type="primary" @click="addinventory">
                    补货
                </el-button>
              </div>
            </div>
      </el-header>
      <el-main>
        <el-form label-width="120px">
          <el-form-item label="商品编号">
            <h1>{{ form.id }}</h1>
          </el-form-item>
          <el-form-item label="商品名">
            <el-input v-model="form.name" />
          </el-form-item>
          <el-form-item label="选择图片">

            <el-upload action="#" list-type="picture-card" :auto-upload="false" file-list="{{this.lists}}">
            <el-icon><Plus /></el-icon>
            <template #file="{ file }">
              <div>
                <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <el-icon><CirclePlus /></el-icon>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                  >
                    <el-icon><Download /></el-icon>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <el-icon><Delete /></el-icon>
                  </span>
                </span>
              </div>
            </template>
          </el-upload>
          <el-dialog v-model="dialogVisible" width="650px">
            <img w-full :src="dialogImageUrl" width="100%" alt="预览" />
          </el-dialog>
          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="form.describe" />
          </el-form-item>
          <el-form-item label="标准价格">
            <el-input v-model="form.money" />
          </el-form-item>
          <el-form-item label="会员价格">
            <el-input v-model="form.vip_money" />
          </el-form-item>
          <el-form-item label="库存">
            <el-input v-model="form.inventory" />
          </el-form-item>
          <el-form-item label="补货" style="margin-top: 20px;">
            <el-input v-model="add" />
          </el-form-item>
        </el-form>
      </el-main>
      
    </el-drawer>
  </div>
  <!-- 预览图片 -->
  <el-dialog v-model="dialogVisibleOut" width="650px">
    <img w-full :src="dialogImageUrlOut" width="100%" alt="预览" />
  </el-dialog>
  <!-- 修改内容信息 -->
  <el-dialog v-model="editDialogVisible" title="编辑商品信息" width="60%">
    <el-form :model="editForm">
      <el-form-item label="商品名">
        <el-input v-model="editForm.name"></el-input>
      </el-form-item>
      <el-form-item label="图片">
        <div class="editorImagesStyle">
          <!-- 这里仅展示一个简单的图片列表，需要根据实际情况进行上传和删除图片的逻辑处理 -->
          <div class="editorImagesRow" v-for="(image, index) in editForm.images" :key="index">
            <img :src="`https://sjgj.xpwangluo.cn/static/${image}`" style="width: 100px; height: 100px;">
            <el-button style="margin-top: 10px;" @click="removeImage(index)">删除</el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="添加新的图片">
        <div class="upload-container">
          <input type="file" multiple @change="handleFiles" accept="image/*">
          <el-button style="margin-left: 10px;" @click="addImageConfirm">确定添加</el-button>
        </div>
        <div class="editorImagesStyle">
          <!-- 这里仅展示一个简单的图片列表，需要根据实际情况进行上传和删除图片的逻辑处理 -->
          <div class="editorImagesRow" v-for="(image, index) in addimages" :key="index">
            <img :src="image.url" style="width: 100px; height: 100px;">
            <el-button style="margin-top: 10px;" @click="removeImageadd(index)">添加的删除</el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="描述">
        <el-input v-model="editForm.description" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="详情介绍">
        <el-input v-model="editForm.detail" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="承诺">
        <el-input v-model="editForm.service" type="textarea"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="editDialogVisible = false">取消</el-button>
      <el-button type="primary" @click="saveEdit">确定</el-button>
    </span>
  </el-dialog>
  <!-- 修改商品属性 -->
  <el-dialog v-model="attributeDialogVisible" title="编辑商品属性" class="attribute-dialog">
      <el-scrollbar style="height: 300px;">
        <el-form
          v-for="(item, index) in selectedProductAttributesList"
          :key="index"
          :model="item"
          label-width="100px"
          class="attribute-form">
          <el-form-item label="商品编号">
            <el-input v-model="item.code" disabled></el-input>
          </el-form-item>
          <el-form-item label="会员价格">
            <el-input v-model="item.memberPrice"></el-input>
          </el-form-item>
          <el-form-item label="一般价格">
            <el-input v-model="item.normalPrice"></el-input>
          </el-form-item>
          <el-form-item label="库存量">
            <el-input v-model="item.stock"></el-input>
          </el-form-item>
          <el-form-item v-for="(spec, specIndex) in item.specsCombination" :key="`spec-${specIndex}`" :label="'规格 ' + (specIndex + 1)">
            <el-input v-model="item.specsCombination[specIndex]"></el-input>
          </el-form-item>
          <el-button type="primary" @click="saveSingleAttribute(item.id)" style="width: 100%;">保存</el-button>
        </el-form>
      </el-scrollbar>
  </el-dialog>
  <!-- 修改销量 -->
  <el-dialog v-model="saleNumDialogVisible" title="修改销量" :before-close="handleClose">
    <el-form :model="selectedProduct">
      <el-form-item label="销量">
        <el-input v-model="selectedProduct.saleNum" type="number" min="0"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="saleNumDialogVisible = false">取消</el-button>
      <el-button type="primary" @click="updateSaleNum">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {Plus, CirclePlus, Download, Delete} from "@element-plus/icons-vue"
import request from "../utils/request"
export default {
  name: "myPost",
  components:{
    Plus,
    CirclePlus,
    Download,
    Delete
  },
  data() {
    return {
      time: "2020年9月9日",
      drawer: false,
      goods:[],
      form:{
        id:'',
        uptime:'',
        name:'',
        describe:'',
        money:'',
        vip_money:'',
        inventory:'',
      },
      add:'',
      file:[],
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      dialogImageUrlOut:'',
      dialogVisibleOut:false,
      multipleSelection: [],
      winowType:'',
      nowtime:'',
      lists:[],
      // 其他数据
      editDialogVisible: false,
      editForm: {
        name: '',
        images: [],
        description: '',
        detail: '',
        service: ''
      },
      addimages:[],
      imagesSend:[],
      tempShopCode:"",
      attributeDialogVisible: false,
      selectedProductAttributesList: [],
      saleNumDialogVisible: false,
      selectedProduct: null,
      mouseDownTimer: null,
    };
  },
  methods: {
    /* 时间格式化 */
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth() + 1; // getUTCMonth() 返回的月份是从 0 开始的
      const day = date.getUTCDate();
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const seconds = date.getUTCSeconds();
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    handleSelectionChange(selection) {
      this.multipleSelection = selection;
    },
    /* 编辑信息 */
    openChangeWindow(index,row) {
      console.log(row);
      this.editForm = {
        name: row.name,
        images: row.images, // 假设row.images是图片URL的数组
        description: row.description,
        detail: row.detail,
        service: row.service
      };
      this.editDialogVisible = true;
      this.tempShopCode=row.id
    },
    /* 移除图片 */
    removeImage(index) {
      console.log(this.editForm.images[index]);
      request({
        url:"/admin/delgoodimages",
        method:"POST",
        data:{
          image:this.editForm.images[index]
        }
      }).then((res)=>{
        this.$notify({
          title: '',
          message: '已删除',
          type: 'warning'
        });
        this.editForm.images.splice(index, 1);
      }).catch((res)=>{
        this.$notify({
            title: '',
            message: '图片删除失败',
            type: 'warning'
        });
      })

    },
    saveEdit() {
      console.log('保存编辑的商品信息', this.editForm);
      // 这里实现保存逻辑
      request({
          url:"/admin/updateshopinfo",
          method:"POST",
          data:{
            id:this.tempShopCode,
            name:this.editForm.name,
            description:this.editForm.description,
            service:this.editForm.service,
            detail:this.editForm.detail
          }
        }).then((res)=>{

          console.log(res);
          if(res.data.success==200){
            this.$notify({
              title: '',
              message: '商品更新成功',
              type: 'warning'
            });
            this.editDialogVisible = false;
            this.getSvip()
          }else{
            this.$notify({
            title: '',
            message: '商品更新失败',
            type: 'warning'
          });
          }
        }).catch((res)=>{
          this.$notify({
            title: '',
            message: '商品更新失败请联系管理员',
            type: 'warning'
          });
        })
    },
    delOne($index,row){
      console.log("删除的用户id",row.id);
      this.$confirm('确认删除商品吗？',"删除商品",{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        request({
          url:"/admin/delteshopInfo",
          method:"POST",
          data:{
            id:row.id
          }
        }).then((res)=>{
          this.$notify({
            title: '',
            message: '已删除',
            type: 'warning'
          });
          this.goods = this.goods.filter((item) => item !== row);
        }).catch((res)=>{
            this.$notify({
            title: '',
            message: '删除失败',
            type: 'warning'
          });
        })
        
      })
    },
    handleSwitchChange() {
      // 在售和已下架的切换逻辑
      this.multipleSelection.forEach((row) => {
        if(row.style==1){
          this.$notify({
            title: '',
            message: '商品已下架',
            type: 'warning'
          });
          row.style=0
        }
      });
    },
    /* 下架 */
    downit(index,row){
        console.log(index,row);
        request({
          url:"/admin/downshop",
          method:"POST",
          data:{
            id:row.id,
            style:1
          }
        }).then((res)=>{
          if(res.data.status==200){
            this.$notify({
              title: '',
              message: '商品已下架',
              type: 'warning'
            });
            this.getSvip()
          }else{
            this.$notify({
            title: '',
            message: '商品下架失败',
            type: 'warning'
          });
          }
        }).catch((res)=>{
          this.$notify({
            title: '',
            message: '商品下架失败请联系管理员',
            type: 'warning'
          });
        })

        
    },
    /* 上架 */
    pulldown(index,row){
        request({
          url:"/admin/downshop",
          method:"POST",
          data:{
            id:row.id,
            style:0
          }
        }).then((res)=>{
          if(res.data.status==200){
            this.$notify({
              title: '',
              message: '商品已上架',
              type: 'warning'
            });
            this.getSvip()
          }else{
            this.$notify({
            title: '',
            message: '商品上架失败',
            type: 'warning'
          });
          }
        }).catch((res)=>{
          this.$notify({
            title: '',
            message: '商品上架失败请联系管理员',
            type: 'warning'
          });
        })
    },
    /* 批量上架 */
    upListItem(){
      this.multipleSelection.forEach((row) => {
        if(row.style==0){
          this.$notify({
            title: '',
            message: '商品已上架',
            type: 'success'
          });
          row.style=1
        }
      });
    },
    /* 补货 */
    addinventory(){
      this.form.inventory = parseInt(this.form.inventory) + parseInt(this.add)
      
    },
    /* 打开添加商品窗口 */
    addGoodsWindow(){
      this.drawer = true;
      this.winowType = 0;
      this.form = {}
    },
    /* 添加商品 */
    addGoods(){
      this.getCurrentTime();
      /* 图片问题 */
      this.$notify({
        title: '',
        message: '商品已上架',
        type: 'success'
      });
      var temp = this.form
      temp.style = 1
      temp.img = this.dialogImageUrl
      temp.uptime = this.nowtime
      this.goods.push(temp)
      console.log(this.dialogImageUrl);
      console.log(this.dialogImageUrlOut);
      console.log(this.lists);
    },
    getCurrentTime() {
      //获取当前时间并打印
      var _this = this;
      let yy = new Date().getFullYear();
      let mm = new Date().getMonth()+1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes()<10 ? '0'+new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds()<10 ? '0'+new Date().getSeconds() : new Date().getSeconds();
      _this.gettime = yy+'/'+mm+'/'+dd+' '+hh+':'+mf+':'+ss;
      console.log(_this.gettime)  
      this.nowtime = _this.gettime
    },
    handleRemove(file) {
        console.log(file);
    },
    handleDownload(file) {
      console.log(file);
    },
    showImg(src){
      this.dialogImageUrlOut=src,
      this.dialogVisibleOut=true
    },
    handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
    },
    /* 获得商品 */
    getSvip(){
            request({
                method:"GET",
                url:"/admin/getshop",
            }).then((res)=>{
                console.log(res);
            if(res.data.status==200){
                    var responseData=res.data.msg
                    console.log(responseData);
                    this.goods=responseData
                }
            })          
    },
    /* 增加图片 */
    handleFiles(event) {
      console.log("增加图片",event);
      const files = Array.from(event.target.files);
      // 限制图片数量
      const filesToAdd = files.slice(0, 6 - this.addimages.length-this.editForm.images.length);
      filesToAdd.forEach(file => {
        const reader = new FileReader();
        reader.onload = e => {
          this.addimages.push({ url: e.target.result, file });
        };
        reader.readAsDataURL(file);
      });

    },
    /* 确定添加图片 */
    addImageConfirm(){
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        this.addimages.forEach((image, index) => {
           formData.append(`file${index}`, image.file);
        });
        // 替换为你的图片上传接口
        fetch('https://sjgj.xpwangluo.cn/admin/images', {
          method: 'POST',
          body: formData,
        })
        .then(response => response.json())
        .then(data => {
          console.log("图片地址",data);
          var imagesTemp=[]
          if(data.status === 'success') {
            this.imagesSend = data.imageNames; // 直接赋值
            console.log("赋值",this.imagesSend);
            resolve(data.imageNames); // 假设后端返回的字段中包含imageNames数组
            this.comeBackSuccess()
          } else {
            reject(data.message);
          }
        })
        .catch(error => {
          console.error('上传图片失败:', error);
          reject(error);
        });
      });
    },
    /* 插入图片回调 */
    comeBackSuccess(){
      if(this.imagesSend.length==0){
        this.$notify({
          title: '',
          message: '请上传新的商品图片',
          type: 'warning'
        });
        return
      }
      request({
        url:"/admin/insertimagesnew",
        method:"POST",
        data:{
          code:this.tempShopCode,
          images:this.imagesSend
        }
      }).then((res)=>{
        if(res.data.status==200){
          this.$notify({
            title: '',
            message: '更新图片成功',
            type: 'warning'
          });
        }
      }).catch((res)=>{
        this.$notify({
          title: '',
          message: '更新失败，请联系技术人员',
          type: 'warning'
        });
      })
    },
    /* 删除更新的图片 */
    removeImageadd(index) {
      this.addimages.splice(index, 1);
    },
    async editAttributes(code) {
      try {
      const response = await request.get('/admin/get-product-attributes', { params: { code } });
      console.log(response.data);
      this.selectedProductAttributesList = response.data.map(item => {
        if (item.specsCombination) {
          // 尝试修正 specsCombination 字段的格式并解析为 JSON
          try {
            const correctedSpecs = item.specsCombination.replace(/'/g, '"');
            item.specsCombination = JSON.parse(correctedSpecs);
          } catch (e) {
            // 如果解析失败，将 specsCombination 设置为一个包含原始字符串的数组
            console.error('Error parsing specsCombination:', e);
            item.specsCombination = [item.specsCombination];
          }
        }
        return item;
      });
      this.attributeDialogVisible = true;
    } catch (error) {
      console.error('Error fetching product attributes:', error);
      this.$message.error('获取商品属性失败');
    }
    },
    async saveSingleAttribute(attributeGroupId) {
      const attributeGroup = this.selectedProductAttributesList.find(item => item.id === attributeGroupId);
      if (attributeGroup) {
        try {
          await request.post('/admin/update-product-attributes', attributeGroup);
          this.$message.success('属性修改成功');
        } catch (error) {
          console.error('Error updating product attributes:', error);
          this.$message.error('属性修改失败');
        }
      }
    },
    /* 修改销量 */
    handleMouseDown(row, event) {
    event.preventDefault(); // 防止选中文本
    this.mouseDownTimer = setTimeout(() => {
      this.selectedProduct = { ...row };
      this.saleNumDialogVisible = true;
    }, 1000); // 长按 1 秒触发
  },
  handleMouseUp() {
    clearTimeout(this.mouseDownTimer);
  },
  handleClose(done) {
    done();
    this.selectedProduct = null; // 清除选中的商品信息
  },
  async updateSaleNum() {
    try {
      await request.post('/admin/update-sale-num', {
        code: this.selectedProduct.id,
        saleNum: this.selectedProduct.saleNum,
      });
      this.$message.success('销量更新成功');
      this.saleNumDialogVisible = false;
      // 重新加载商品数据
      this.getSvip()
    } catch (error) {
      console.error('Error updating sale number:', error);
      this.$message.error('销量更新失败');
    }
  },
      
  },
  mounted:function(){
        this.getSvip()
  },
};
</script>

<style scoped>
 .upload{
    width: 800px;
  }

  .demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

  .el-tabs--right .el-tabs__content,
  .el-tabs--left .el-tabs__content {
    height: 100%;
}

.money {
  font-size: 20px;
  color: #fb1515;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 0;
  min-height: auto;
}

.image {
  width: 100%;
  display: block;
}
.editorImagesStyle{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.editorImagesRow{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}
.upload-container{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.attribute-dialog .el-scrollbar__wrap {
  margin-right: -17px; /* Adjust based on actual situation to prevent double scrollbar */
}

.attribute-form {
  margin-bottom: 20px; /* Space between attribute forms */
}
</style>
