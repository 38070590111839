/*
 * @Author: xpaibiancheng 486957763@qq.com
 * @Date: 2024-01-30 11:14:31
 * @LastEditors: xpaibiancheng 486957763@qq.com
 * @LastEditTime: 2024-02-22 21:17:45
 * @FilePath: \sjgj_Vue\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp , reactive} from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from '@/router'

// const globalData={
//     url:"http://127.0.0.1"
// }
// 设置全局变量
// 创建全局状态对象
const globalStore = reactive({
    user: localStorage.getItem('user') || '',
    name: localStorage.getItem('name') || '',
    identity: localStorage.getItem('identity') || ''
});
// 使用provide提供全局状态

const app = createApp(App)
app.provide('globalStore', globalStore);
app.use(ElementPlus)
app.use(router)
app.mount('#app')