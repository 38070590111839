<template>
    <div id="admin">
      <el-header  style="padding-left:0;padding-right: 0;display: flex;flex-direction: row;justify-content: space-between;">
        <div>
            <el-input v-model="search" size="millde" placeholder="搜索员工" style="width: 300px;margin-right: 10px;"/>
            <el-button type="primary">搜索</el-button>
        </div>
      </el-header>
      <el-table :data="user" style="width: 100%" class="yuan">
        <el-table-column align="center" width="120" sortable label="id" prop="id" />
        <el-table-column align="center" sortable width="300" label="姓名" prop="name" />
        <el-table-column sortable width="120" align="center" label="手机号" prop="phone" />
        <el-table-column align="center" sortable label="密码" prop="password" />
        <el-table-column sortable label="注册日期" prop="registeredTime" />
        <el-table-column sortable label="入职日期" prop="offerTime" />
        <el-table-column sortable label="职位" prop="type" />
        <el-table-column sortable label="状态" prop="alive">
          <template #default="{ row }">
            <div style="position: relative;">
              <div v-show="row.alive" style="position: relative">可用<span style="white-space: pre;">&nbsp;&nbsp;&nbsp;</span></div>
              <div v-show="!row.alive" style="color: #ff0000;position: relative">已禁用<span style="white-space: pre;">&nbsp;&nbsp;&nbsp;</span></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="300" align="center" label="操作">
          <template #default="{ $index, row }">
            <el-popconfirm title="激活此用户？"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="aliveIt($index, row)"
            >
              <template #reference>
                <el-button size="small" type="success" @click="openDrawer(row)">激活</el-button>
              </template>
            </el-popconfirm>
            <el-popconfirm title="禁用此用户？"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="blackIt($index, row)"
            >
              <template #reference>
                <el-button size="small" type="primary" @click="openDrawer(row)">禁用</el-button>
              </template>
            </el-popconfirm>
            <el-popconfirm 
            title="确认删除吗？（删除后不可恢复）"
            confirm-button-text="确认"
            cancel-button-text="取消"
            @confirm="handleDelete($index, row)"
            >
              <template #reference>
                <el-button size="small" type="danger">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </template>
  
  <script>
  export default {
    name: 'myAdmin',
    data() {
      return {
        user: [
    
        ],
        drawer: false,
        editingUser: null,
      };
    },
    methods: {
      openDrawer(row) {
        this.editingUser = row;
        // this.drawer = true;
        console.log(this.drawer);
      },
      blackIt(index, row){
        row.alive = 0
      },
      aliveIt(index, row){
        row.alive = 1
      },
      handleDelete(index, row) {
        this.user = this.user.filter((item) => item.id !== row.id);
      },
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  